import { useState } from "react";
import { XIcon } from "../Icons";
import { NumberField, PlusMinusNumberField } from "./EditableField";
import store from "../../app/store";
import { setPendingFieldArticle } from "../../features/ficheSlice";
import Loader from "../Loader";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import {Dialog} from "../Modals";

export function LigneArticle({article, socket}) {

    const [status, setStatus] = useState(null);
    const device = useSelector((state) => state.device);

    const [modalOpen, setModalOpen] = useState(false);

 
    const onChangeStatus = (field, value) => {
        if (value) setStatus(field);
        else setStatus(null);
    }

    const deleteArticleLocation = () =>{
        setModalOpen(false);
        store.dispatch(setPendingFieldArticle({id:article.indice, fieldName:'delete', pending : true}));
        socket.current.emit("removeArticle", {indice:article.indice, ean13:article.ean13});
    }

    const onChangeDuree = (val) => {
        val = parseFloat(val);
        if (isNaN(val) || val <=0 || val === article.duree) {
            setStatus(null);
            return;
        }
        store.dispatch(setPendingFieldArticle({id:article.indice, fieldName:'duree', pending : true}));
        socket.current.emit("updateDuree", {indice:article.indice, ean13:article.ean13, duree:val});
        setStatus(null);
    }
 
 
    const onChangeSupplement = (val) => {
        val = parseFloat(val);
        if (isNaN(val) || val == article.supplement){
            setStatus(null);
            return;
        }
        store.dispatch(setPendingFieldArticle({id:article.indice, fieldName:'supplement', pending : true}));
        socket.current.emit("updateSupplement", {indice:article.indice, ean13:article.ean13, supplement:val});
        setStatus(null);
    }

 
    return (
        <tr className="LignePanier">
            {device.width>device.smallBreakpoint ?<td>{article.code}</td> : null}
            <td>{article.categorie}</td>
            <td>{dayjs(article.dateDebut).format('DD/MM/YYYY HH:mm')}</td>
            {device.width>device.smallBreakpoint ?<td>{dayjs(article.dateFin).format('DD/MM/YYYY HH:mm')}</td> : null}
            <td>
                <PlusMinusNumberField
                    step = {0.5}
                    inputStyle = {{width: 20}}
                    edit = {status === 'duree'}
                    onFocusOut = {onChangeDuree}
                    initialValue = {article.duree}
                    loading = {article.pendingUpdate.duree}
                    setEdit = {(v)=> onChangeStatus("duree", v)}/>
            </td>
            {device.width>device.smallBreakpoint ? <td>{(article.montant)}&nbsp;&euro;</td> : null}
            <td>
                <NumberField
                    step = {0.01}
                    initValue = {article.supplement}
                    edit = {status === 'supplement'}
                    onFocusOut = {onChangeSupplement}
                    unit = {String.fromCodePoint(8364)}
                    inputStyle = {{width:50, textAlign:'center'}}
                    loading = {article.pendingUpdate.supplement}
                    setEdit = {(v)=> onChangeStatus("supplement", v)}/>
            </td>
            <td>{(article.prix)}&nbsp;&euro;</td>
            <td>
                <button onClick={() => setModalOpen(true)}><XIcon/></button>
                {article.pendingUpdate.delete ? <div className="overlay"><Loader width={20} height={20}/></div> : null}
                {modalOpen ? 
                    <Dialog confirmText="Oui"
                        confirmCallback={deleteArticleLocation}
                        cancelText="Non"
                        cancelCallback={() => setModalOpen(false)}>
                        <span>Supprimer l'article de la fiche ?</span>
                    </Dialog>
                    :null
                }
            </td>
        </tr>
     )
 }