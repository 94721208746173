

import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Title } from './Title';
import { BoxIcon, PlanningIcon, RefreshIcon, StatsIcon } from './Icons';
import Loader from './Loader';
import { clearAllShopsData, clearShopData, setShopData } from '../features/shopSlice';
import { getChiffreAffairesGeneral } from '../api/statistiquesApi';
import { addErrorMessage } from '../features/errorSlice';
import { Masonry } from '@mui/lab';
import ChiffreAffaires from './statistiques/ChiffreAffaires';
import CaisseVente from './statistiques/CaisseVente';
import CaisseLocation from './statistiques/CaisseLocation';
import PerformancesCategorie from './statistiques/PerformancesCategorie';
import Palmares from './statistiques/Palmares';


import '../styles/supervisorStatistiques.css'

export default function ListShopsStatistiques ({shops}) {

    const today = dayjs().format('YYYY-MM-DD');

    return (
        <div className="SupervisorStatistiques">
            <Title title="Statistiques générales"> <StatsIcon/></Title>
            <div className="Block">
                {shops.length > 0 ?
                [
                    <LigneGlobal key='all' shops={shops}/>,
                    shops.map((shop)=>{
                        return <LigneShop key={shop.id} shop={shop} date={today}/>
                    })

                ] : <Loader/>
                
                
                }
            </div>
        </div>
    )
}


function LigneShop ({shop, date}) {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [showDetail, setShowDetail] = useState(false);

    const onClick = (e) => {
        e.stopPropagation();
        if (!showDetail && shop.data?.date != null){
            setShowDetail(true);
        }
        else if (showDetail) setShowDetail(false);
    }

    const refreshData = () => {
        dispatch(clearShopData({id:shop.id}));
    }

    useEffect(()=>{
        if (shop.data == null) {
            setIsLoading(true);
            getChiffreAffairesGeneral(date, shop.id)
            .then( res =>{
                setIsLoading(false);
                dispatch(setShopData({id:shop.id, data:{ ...res, timestamp:dayjs().format('HH:mm')}}));
            })
            .catch(error =>{
                console.log(error);
                setIsLoading(false);
                dispatch(addErrorMessage({type:"error"}));
            });
        }
    }, [shop.data]);

    return (
        <div>
            <div onClick={onClick} className={"ligneShop" + (shop.data?.date != null ? " connected" : "")}>
                <div>
                    {isLoading ?
                        <Loader height={15} width={15}/> : 
                        <div className={"dot " + (shop.data?.date != null ? "green" : "red")}></div>
                    }
                    <span className="shopName">{shop.name}</span>
                    
                </div>
                <div>
                    <LigneShopChiffreAffaires data={shop.data} isLoading={isLoading} refreshData={refreshData}/>
                </div>
                <div>
                    <button onClick={(e)=>{e.stopPropagation();navigate('stocks/'+shop.id)}} ><BoxIcon/></button>
                    <button onClick={(e)=>{e.stopPropagation();navigate('planning/'+shop.id)}}><PlanningIcon/></button>
                </div>
            </div>
            {showDetail ? <DetailShopStatistiques shop={shop}/> : null}
        </div>
    )

}


function LigneGlobal ({shops}) {
    
    const dispatch = useDispatch();
    const refreshData = () => {
        dispatch(clearAllShopsData());
    }


    let data = shops.filter((s) => s.data?.date != null).reduce((a, b) => {
        return {
            date: b.data.date,
            timestamp: b.data.timestamp,
            chiffreAffairesExN : a.chiffreAffairesExN + (b.data?.chiffreAffairesExN != null ? b.data?.chiffreAffairesExN : 0),
            chiffreAffairesExN_1 : a.chiffreAffairesExN_1 + (b.data?.chiffreAffairesExN_1 != null ? b.data?.chiffreAffairesExN_1 : 0),
            chiffreAffairesJ30N : a.chiffreAffairesJ30N + (b.data?.chiffreAffairesJ30N != null ? b.data?.chiffreAffairesJ30N : 0),
            chiffreAffairesJ30N_1 : a.chiffreAffairesJ30N_1 + (b.data?.chiffreAffairesJ30N_1 != null ? b.data?.chiffreAffairesJ30N_1 : 0),
            chiffreAffairesJ7N : a.chiffreAffairesJ7N + (b.data?.chiffreAffairesJ7N != null ? b.data?.chiffreAffairesJ7N : 0),
            chiffreAffairesJ7N_1 : a.chiffreAffairesJ7N_1 + (b.data?.chiffreAffairesJ7N_1 != null ? b.data?.chiffreAffairesJ7N_1 : 0),
            chiffreAffairesJN : a.chiffreAffairesJN + (b.data?.chiffreAffairesJN != null ? b.data?.chiffreAffairesJN : 0),
            chiffreAffairesJN_1 : a.chiffreAffairesJN_1 + (b.data?.chiffreAffairesJN_1 != null ? b.data?.chiffreAffairesJN_1 : 0),
        };
    }, {
        chiffreAffairesExN : 0,
        chiffreAffairesExN_1 : 0,
        chiffreAffairesJ30N : 0,
        chiffreAffairesJ30N_1 : 0,
        chiffreAffairesJ7N : 0,
        chiffreAffairesJ7N_1 : 0,
        chiffreAffairesJN : 0,
        chiffreAffairesJN_1 : 0,
    }); 

    return (
        <div className="ligneShop all">
            <div>
                <button><RefreshIcon onClick={refreshData}/></button>
                <span className="shopName">Global</span>
            </div>
            <div style={{marginLeft: "auto"}}>
                <LigneShopChiffreAffaires data={data} isLoading={false} refreshData={refreshData} displayHeader={true}/>
                <span className="timestamp">{data?.timestamp}</span>
            </div>
        </div>
    )

}


function LigneShopChiffreAffaires ({data, isLoading, refreshData, displayHeader=false}) {

    return (
        <table className="chiffreAffaires">
            {displayHeader ?
                <thead>
                    <tr>
                        <th>J</th>
                        <th>J-7</th>
                        <th>J-30</th>
                        <th>Exercice</th>
                    </tr>
                </thead>
                :null
            }
            <tbody>
                <tr>
                    <td className="ca">
                        {data?.chiffreAffairesJN == null ? null
                        : (data?.chiffreAffairesJN.toFixed(2) + String.fromCodePoint(8364))}
                    </td>
                    <td className="ca7">
                        {data?.chiffreAffairesJ7N == null ? null 
                        : (data?.chiffreAffairesJ7N.toFixed(2) + String.fromCodePoint(8364))}
                    </td>
                    <td className="ca30">
                        {data?.chiffreAffairesJ30N == null ? null
                        : (data?.chiffreAffairesJ30N.toFixed(2) + String.fromCodePoint(8364))}
                    </td>
                    <td className="caEx">
                        {!isLoading && data?.date == null ?
                            <button onClick={refreshData}><RefreshIcon/></button> :
                            (data?.chiffreAffairesExN == null ? 
                                "-" : 
                                (data?.chiffreAffairesExN.toFixed(2) + String.fromCodePoint(8364))
                            )
                        }
                    </td>
                </tr>
                <tr>
                    <td className="ca N-1">
                        {data?.chiffreAffairesJN_1 == null ? null
                        : (data?.chiffreAffairesJN_1.toFixed(2) + String.fromCodePoint(8364))}
                    </td>
                    <td className="ca7 N-1">
                        {data?.chiffreAffairesJ7N_1 == null ? null
                        : (data?.chiffreAffairesJ7N_1.toFixed(2) + String.fromCodePoint(8364))}
                    </td>
                    <td className="ca30 N-1">
                        {data?.chiffreAffairesJ30N_1 == null ? null
                        : (data?.chiffreAffairesJ30N_1.toFixed(2) + String.fromCodePoint(8364))}
                    </td>
                    <td className="caEx N-1">
                        {data?.chiffreAffairesExN_1 == null ? "-" : 
                            (data?.chiffreAffairesExN_1.toFixed(2) + String.fromCodePoint(8364))
                        }
                    </td>
                </tr>
            </tbody>
        </table>
    )

}



function DetailShopStatistiques ({shop}) {

    const date = dayjs().format('YYYY-MM-DD');


    return (
        <div className="detailShop">
            <DetailShopStatistiquesContainer>
                <ChiffreAffaires id={shop.id} date={date}/>
                <CaisseVente id={shop.id} date={date}/>
                <CaisseLocation id={shop.id} date={date}/>
                <PerformancesCategorie id={shop.id} date={date}/>
                <Palmares of={"familles"} id={shop.id} date={date} top={10}/>
                <Palmares of={"produits"} id={shop.id} date={date} top={10}/>
            </DetailShopStatistiquesContainer>
        </div>
    );
}

function DetailShopStatistiquesContainer({children}) {
    const device = useSelector((state) => state.device);
    const small = device.width<device.smallBreakpoint


    return (
        small ?
        <div>{children}</div> :
        <Masonry columns={{ sm: 1, md: 2, xl: 3 }} spacing={2} sx={{ width: "auto" }}>{children}</Masonry>
    )
}