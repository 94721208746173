import { useEffect, useState } from "react";
import { LockedInput } from "../Inputs";
import { StandardButton, UnderlindedButton } from "../Buttons";

import '../../styles/gestionProduits.css';
import '../../styles/table.css';

export default function ModificationProduit ({currentProduit, setCurrentProduit}){

    const [formData, setFormData] = useState({
        prixAchat: getPrixProduit(currentProduit, "prixAchatHT"),
        prixVente:  getPrixProduit(currentProduit, "prixTTC")
    });

    const [errors, setErrors] = useState({
        prixAchat: false,
        prixVente: false
    })

    const listeTailles = currentProduit.tailles.reduce(function(a, b){
        return a +" "+ b.libelle+",";
    }, "");

    const listeCouleurs = getListeCouleurs(currentProduit).reduce(function(a, b){
        return a +" "+ b.libelle+",";
    }, "");;
    
    const handleChange = (e) =>{
        const { name, value } = e.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
        setErrors({...errors, [name]: false});
    }

    useEffect(() => {
        setFormData({
            prixAchat: getPrixProduit(currentProduit, "prixAchatHT"),
            prixVente:  getPrixProduit(currentProduit, "prixTTC")
        });

    }, [currentProduit]);

    return (
        [
            <div key="form">
                <div className="formModification">
                    <LockedInput
                        name="famille"
                        label="Famille"
                        className="famille"
                        value={currentProduit.famille}/>
                    <LockedInput
                        name="fournisseur"
                        label="Fournisseur"
                        className="fournisseur"
                        value={currentProduit.fournisseur}/>
                    <LockedInput
                        name="libelle"
                        label="Libellé"
                        className="libelle"
                        value={currentProduit.libelle}/>
                    <LockedInput
                        name="codeProduit"
                        label="Code Produit"
                        className="codeProduit"
                        value={currentProduit.code}/>
                    <LockedInput
                        name="tailles"
                        label="Tailles"
                        className="taille"
                        value={listeTailles.substring(0, listeTailles.length - 1)}/>
                    <LockedInput
                        name="couleurs"
                        label="Couleurs"
                        className="couleur"
                        value={listeCouleurs.substring(0, listeCouleurs.length - 1)}/>
                    <LockedInput
                        name="prixAchat"
                        label="Prix d'achat"
                        className="prixAchat"
                        value={formData.prixAchat}/>
                    <LockedInput
                        name="prixVente"
                        label="Prix de vente"
                        className="prixVente"
                        value={formData.prixVente}/>
                
                    {/* <TextInput 
                        className={"prixAchat "+ (errors.prixAchat ? "error": null)} 
                        label="Prix d'achat" 
                        name="prixAchat"
                        value={formData.prixAchat}
                        onChange={handleChange}/>
                    <TextInput 
                        className={"prixVente "+ (errors.prixVente ? "error": null)} 
                        label="Prix de vente" 
                        name="prixVente"
                        value={formData.prixVente}
                        onChange={handleChange}/>  */}
                </div>
            </div>,
            <div key="footer" className="footer">
                <UnderlindedButton label="Abandon" onClick={() => setCurrentProduit(null)}/>
                <StandardButton label="Suivant"/>
            </div>
        ]
    )
}


const getListeCouleurs = (produit) => {
    let couleurs = [];

    produit.tailles.forEach((t) => {
        t.couleurs.forEach((c) => {
            if (couleurs.find((el) => el.code === c.code) == null){
                couleurs.push({
                    code:c.code,
                    libelle:c.libelle
                })
            }
        });
    });

    return couleurs;
}

function getPrixProduit (produit, typePrix) {
    let res = "";
    for (const t of  produit.tailles) {
        for (const c of t.couleurs) {
            if (res != "" && c[typePrix] != res) return "";
            res = c[typePrix];
        }
    }
    return res;
}
