import errorHandler from "../utils/ErrorHandler";

export async function consultationAide (libelle) {
    await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/aide/'+libelle, {
        method: 'GET',
        credentials: 'include',
    })
    .catch(err=>{
        console.log(err);
    })
}

export async function consultationTuto (libelle) {
    await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/aide/tuto/'+libelle, {
        method: 'GET',
        credentials: 'include',
    })
    .catch(err=>{
        console.log(err);
    })
}

export async function getLogsConsultation (by) {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/aide/logs/'+by, {
        method: 'GET',
        credentials: 'include',
    })
    
    if (response.status>=500) throw new Error("getLogsConsultation api error");
    let result = await response.json();
    return errorHandler(result);
}