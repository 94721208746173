import { HomeHeader } from "../../components/HomeHeaderFooter.js";
import { Masonry } from "@mui/lab";
import AideConsultationWidget from "../../components/admin/AideConsultation.js";
import ConnectionStatusWidget from "../../components/admin/ConnectionStatus.js";
import UserLoginFailed from "../../components/admin/UseLoginFailed.js";
import ActiveSessions from "../../components/admin/ActiveSessions.js";
import OpenedSockets from "../../components/admin/OpenedSockets.js";


export default function AdminHome (){

    return (
        <div className="AdminHome">
            <HomeHeader style={{width:'100%'}}/>
            <div>
                <Masonry columns={{ sm: 1, md: 2, xl: 3 }} spacing={2} sx={{ width: "auto" }}>
                    <ConnectionStatusWidget/>
                    <AideConsultationWidget/>
                    <UserLoginFailed/>
                    <ActiveSessions/>
                    <OpenedSockets/>
                </Masonry>
            </div>
        </div>
    )
}

