import { Link } from "react-router-dom";
import { useSelector } from 'react-redux'
import { EuroIcon, SliderIcon, TeddyBearIcon } from "../../components/Icons.js";



import '../../styles/home.css';
import { HomeFooter, HomeHeader } from "../../components/HomeHeaderFooter.js";


export default function SupervisorHome (){

    const device = useSelector((state) => state.device);
    const small = device.width<device.smallBreakpoint;

    return (
        <div className='Home Supervisor'>
            <HomeHeader/>
            <div className="buttonWrapper">
                <Link to={'produits'}>
                    <TeddyBearIcon/>
                    <span>{small? "Produits" : "Gérer les produits"}</span>
                </Link>  
                <Link to={'prix'}>
                    <EuroIcon/>
                    <span>{small? "Tarifs" : "Modifier les tarifs de location"}</span>
                </Link>   
                <Link to={'shops'}>
                    <SliderIcon/>
                    <span>{small? "Magasins" : "Administrer les magasins"}</span>
                </Link>         
            </div>
            <HomeFooter/>
        </div>
    )
}