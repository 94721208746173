import dayjs from "dayjs";
import { useState } from "react";
import { BlockTitle, Title } from "../components/Title";
import Filler from "../components/Filler";
import { useDispatch, useSelector } from "react-redux";
import { createReduction } from "../api/articlesApi";
import { addErrorMessage } from "../features/errorSlice";
import { DateInput, TextAreaInput, TextInput } from "../components/Inputs";
import { ExitIcon } from "../components/Icons";
import { StandardButton, UnderlindedButton } from "../components/Buttons";
import { ErrorTooltip } from "../components/Tooltips";
import ListePacks from "../components/ListePacks";

import '../styles/reductionAllotement.css'
import { VideoLink } from "../components/VideoLink";




export default function ReductionAllotement (){

    const [selectedPack, setSelectedPack] = useState(null);

    return (
        <div className="ReductionAllotement">
            <Title title="Sortie de parc" backTo="/gestion"><ExitIcon/></Title>
            <div className="Block">
                <BlockTitle title="Mes catégories de location"/>
                <ListePacks onClickPack={setSelectedPack} selectedPack={selectedPack}/>
            </div>
            <div className="Block">
                {selectedPack == null ? 
                    <Filler text="Sélectionnez un article dans la liste pour effectuer une sortie de parc"/> :
                    <FormReductionAllotment pack={selectedPack} setSelectedPack={setSelectedPack}/>

                }
            </div>
            <VideoLink link={"https://www.youtube.com/embed/e_rhk-Ze71c?si=qe_UDkO6JBUR3J2u"} libelle='sortieParc'/>
        </div>
    )
}

function FormReductionAllotment ({pack, setSelectedPack}){

    const dispatch = useDispatch();

    const device = useSelector((state) => state.device);
    const small = device.width<device.smallBreakpoint


    const [errors, setErrors] = useState({
        debut:false,
        fin: false,
        quantite: false,
        quantiteMax: false,
        commentaire: false
    })

    const [formData, setFormData] = useState({
        debut: dayjs(),
        fin: null,
        quantite: 1,
        commentaire:''
    });

    const handleDateDebutChange = (val) => {
        setFormData({...formData, debut:val});
        setErrors({...errors, debut: false});
    }

    const handleDateFinChange = (val) => {
        setFormData({...formData,fin:val});
        setErrors({...errors, fin: false});
    }

    const handleChange = (e) =>{
        const { name, value } = e.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
        if (name === "quantite")  setErrors((prevErrors) => ({ ...prevErrors, quantite: false, quantiteMax:false }));
        else setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
    }

    const enregistreReduction = () => {
        let newErrors = {...errors};

        if (formData.debut === null || formData.debut.format('YYYY-MM-DD') < dayjs().format('YYYY-MM-DD')) newErrors.debut = true;
        if (formData.fin === null || formData.fin.format('YYYY-MM-DD') < formData.debut.format('YYYY-MM-DD')) newErrors.fin = true;
        
        let quantite = parseInt(formData.quantite);
        if (isNaN(quantite) || quantite<1)  newErrors.quantite = true;
        else if (quantite > 10) newErrors.quantiteMax = true;

        if (formData.commentaire == "") newErrors.commentaire = true;

        setErrors(newErrors)

        for (const [key, value] of Object.entries(newErrors)) {
            if (value) return;
        }

        let data = {
            debut : formData.debut.format('YYYY-MM-DD'),
            fin : formData.fin.format('YYYY-MM-DD'),
            quantite : quantite,
            commentaire: formData.commentaire
        }


        createReduction(pack.id, data)
        .then(res=> {
            if (res) {
                dispatch(addErrorMessage({type:"success", message:"La sortie des articles a été enregistrée avec succès"}));
                setSelectedPack(null);
            }

        })
        .catch(err =>{
            console.log(err);
            dispatch(addErrorMessage({type:"error"}));
        });

    }

    const onClickBackButton = () => {
        setSelectedPack(null);
    }


    return (
        <div>
            {small ? null : <BlockTitle title={pack.nom}/>}
            <div className="form">
                <div className="debut">
                    <DateInput label="Début" className={(errors.debut ? ' error' : '')} name="dateDebut" value={formData.debut} onChange={handleDateDebutChange} minDate={dayjs()}/>
                </div>
                <div className="fin">
                    <DateInput label="Fin" className={"fin" + (errors.fin ? ' error' : '')} name="dateFin" value={formData.fin} onChange={handleDateFinChange} minDate={formData.debut}/>
                </div>
                <ErrorTooltip title={"La quantité maximale est de 10"} open={errors.quantiteMax} placement={small ? "bottom" :"right"}>
                    <TextInput 
                        type='number'
                        label="Quantité" 
                        className={"quantite " + (errors.quantite ? ' error' : '')}
                        name="quantite"
                        value={formData.quantite}
                        onChange={handleChange}/>
                </ErrorTooltip>
                                    
                <TextAreaInput className={"commentaire "+ (errors.commentaire ? 'error' : '')} name="commentaire" value={formData.commentaire} onChange={handleChange}/>

                <div className="submit">
                    <UnderlindedButton label="Abandon" onClick={onClickBackButton}/>
                    <StandardButton label="Enregistrer" onClick={enregistreReduction}/>
                </div>
            </div>
        </div>
    )
}
