import { useState, useEffect, useRef } from "react";
import { useNavigate, useBlocker } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { io } from "socket.io-client";
import { Location } from '../components/fiche/Location.js';
import { Vente } from '../components/fiche/Vente.js';
import { Client } from '../components/fiche/Client.js';
import { Reglement } from '../components/fiche/Reglement.js';
import { TextInput } from "../components/Inputs.js";
import { addErrorMessage } from '../features/errorSlice.js';
import { FicheIcon } from '../components/Icons.js';
import {Dialog, Modal} from '../components/Modals.js';
import { closeFiche, setNumeroFiche, setTypeFiche } from '../features/ficheSlice.js';
import { onAddProduit, onUpdatePrix, onUpdateQuantite, onRemoveProduit, onAddPaiement, onDeletePaiement } from '../handlers/venteHandlers.js';
import { onAddArticle, onEnregistreClient, onUpdateDuree, onUpdateSupplement, onRemoveArticle } from '../handlers/locationHandlers.js';

import Loader from "../components/Loader.js";
import {Title} from "../components/Title.js";
import { checkEmail } from "../utils/Commons.js";
import { envoiTicket, getVoucher } from "../api/ticketsApi.js";
import { StandardButton } from "../components/Buttons.js";

import '../styles/Block.css';
import '../styles/panier.css';
import '../styles/fiche.css';
import { VideoLink } from "../components/VideoLink.js";

export default function Fiche({typeFiche}){

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const type = useSelector((state) => state.fiche.type);
    const voucher = useSelector((state) => state.user.parametres.voucher);
    const pendingPaiements = useSelector((state) => state.fiche.pendingPaiements);

    const numeroFiche = useSelector((state) => state.fiche.numeroFiche);

    const retourAccueil = useSelector((state) => state.user.parametres.retourAccueilFiche);

    const contenu = useSelector((state) => typeFiche == "Vente" ? state.fiche.produits : state.fiche.articles);

    const [envoiTickets, setEnvoiTickets] = useState({
        ticket: false,
        voucher: typeFiche === "Vente"
    });



    const socket = useRef(io(process.env.REACT_APP_SERVER_BASE_URL, {
        autoConnect:false,
        withCredentials:true,
        transports: ['websocket'], 
        upgrade: false
    }));

    var blocker = useBlocker(
        ({ currentLocation, nextLocation }) =>
            socket.current.connected 
            && contenu.length >0 
            && currentLocation.pathname !== nextLocation.pathname
    );

    const onBlockerProceed = () => {
        blocker.proceed();
    }

    const onBlockerReset = () => {
        blocker.reset();
    }


    const onConnect = () =>{
        console.log("connect socket");
        dispatch(setTypeFiche({type:typeFiche}));
    }

    const onDisconnect = (reason) =>{
        console.log("disconnect "+reason);
        switch(reason){
            case 'io server disconnect' : //fermeture de fiche, client non connecte
                break;
            case 'io client disconnect':
                if (contenu.length > 0) dispatch(addErrorMessage({type:"warning", message:"La fiche en cours a été abandonnée"}));
                navigate('/');
                break;
            case 'transport close': //connexion coupee
            case 'transport error':
            case 'ping timeout':
                dispatch(addErrorMessage({type:"error", message:"La connexion avec le serveur a été perdue ("+reason+")"}));
                navigate('/');
                break;
            default:
                navigate('/');
                break;
        }
    }

    const onConnectionError = (data) =>{
        console.log("connection error");
        console.log(data);
        dispatch(addErrorMessage({type:"warning", message:data.message}));
        navigate('/');
    }

    const onGenericConnectionError = () =>{
        console.log("connection error");
        dispatch(addErrorMessage({type:"error", message:"Impossible d'ouvrir la connexion avec le serveur"}));
        navigate('/');
    }

    const onEnregistreFiche = (response) => {
        if (!response.success) {
            dispatch(addErrorMessage({type:"warning", message:response.message}));
            return;
        }
        if (response.idResa != null) setEnvoiTickets({...envoiTicket, ticket:true}); //pour une reservation pas d'envoi de ticket
        dispatch(setNumeroFiche({numeroFiche:response.codeFiche}));
    }

    useEffect(() => {
        socket.current.on("connect", onConnect);
        socket.current.on("disconnect", onDisconnect);
        socket.current.on("addProduit", onAddProduit);
        socket.current.on("updateQuantite", onUpdateQuantite);
        socket.current.on("updatePrix", onUpdatePrix);
        socket.current.on("removeProduit", onRemoveProduit);
        socket.current.on("addArticle", onAddArticle);
        socket.current.on("updateSupplement", onUpdateSupplement);
        socket.current.on("updateDuree", onUpdateDuree);
        socket.current.on("removeArticle", onRemoveArticle);
        socket.current.on("addPaiement", onAddPaiement);
        socket.current.on("enregistreVente", onEnregistreFiche);
        socket.current.on("enregistreLocation", onEnregistreFiche);
        socket.current.on("useReservation", onEnregistreFiche);
        socket.current.on("enregistreClient", onEnregistreClient);
        socket.current.on("deletePaiement", onDeletePaiement);
        socket.current.on("connectionError", onConnectionError);
        socket.current.on("connect_error", onGenericConnectionError);

        socket.current.connect();

        return () => {
            dispatch(closeFiche()); //on supprime les donnees de la fiche

            socket.current.close();

            socket.current.off("connect", onConnect);
            socket.current.off("disconnect", onDisconnect);
            socket.current.off("addProduit", onAddProduit);
            socket.current.off("updateQuantite", onUpdateQuantite);
            socket.current.off("updatePrix", onUpdatePrix);
            socket.current.off("removeProduit", onRemoveProduit);
            socket.current.off("addArticle", onAddArticle);
            socket.current.off("updateSupplement", onUpdateSupplement);
            socket.current.off("updateDuree", onUpdateDuree);
            socket.current.off("removeArticle", onRemoveArticle);
            socket.current.off("addPaiement", onAddPaiement);
            socket.current.off("enregistreVente", onEnregistreFiche);
            socket.current.off("enregistreLocation", onEnregistreFiche);
            socket.current.off("useReservation", onEnregistreFiche);
            socket.current.off("enregistreClient", onEnregistreClient);
            socket.current.off("deletePaiement", onDeletePaiement);
            socket.current.off("connectionError", onConnectionError);
            socket.current.off("connect_error", onGenericConnectionError);

        }
    }, []);

    useEffect(() => {
        if (numeroFiche == null) window.addEventListener("beforeunload", alertUser);
        else window.removeEventListener("beforeunload", alertUser);

        return () => {
            window.removeEventListener("beforeunload", alertUser);
        }
        
    }, [numeroFiche]);


    const alertUser = (e) => {
        e.preventDefault();
    };

    useEffect(() => {
        if (numeroFiche != null && envoiTickets.ticket && envoiTickets.voucher){
            if (retourAccueil) navigate('/');
            else navigate(0);
            
        }
    }, [envoiTickets, numeroFiche]);

    useEffect(() => {
        
        if (typeFiche === "Location")setEnvoiTickets({...envoiTickets, voucher:!voucher});

    }, [voucher]);


    return (
        <div className="Fiche">
             <Title title={"Fiche de "+type?.toLowerCase()}><FicheIcon/></Title>

            {pendingPaiements>0 ? <div className="overlay"><Loader/></div> : null}

            {type === 'Location'?<Client socket={socket}/> : null}

            {type === 'Location'?<Location socket={socket}/> : null}
            
            {type === 'Vente'?<Vente socket={socket}/> : null}

            <div>
                <Reglement socket={socket}/>

                {blocker.state === "blocked" ? 
                    <Dialog 
                        confirmText="Quitter"
                        confirmCallback={onBlockerProceed}
                        cancelText="Annuler"
                        cancelCallback={onBlockerReset}>
                        <span>Attention, si vous quittez cette page, la fiche en cours sera abandonnée.</span>
                    </Dialog>
                    : null
                }
                
                { numeroFiche != null && !envoiTickets.ticket ? <ModalTicket numeroFiche={numeroFiche} envoiTickets={envoiTickets} setEnvoiTickets={setEnvoiTickets}/> : null }
                { numeroFiche != null && envoiTickets.ticket && !envoiTickets.voucher ? <ModalVoucher numeroFiche={numeroFiche} envoiTickets={envoiTickets} setEnvoiTickets={setEnvoiTickets}/> : null }
                <VideoLink link={typeFiche == "Location" ? "https://www.youtube.com/embed/WMpgx__Idqo?si=iax_gHUHgCyAKM76" : "https://www.youtube.com/embed/-nj6OIPdgBQ?si=zdFW9mUoHyy7NzHx"} libelle={typeFiche == "Location" ? 'location' : 'vente'}/>
            </div>
        </div>
    )
}


function ModalTicket ({numeroFiche, envoiTickets, setEnvoiTickets}) {

    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    

    const confirmEnvoiTicket = () => {
        if (!checkEmail(email)) {
            setErrorMsg("L'email saisi est invalide");
            return;   
        } 
        setIsLoading(true);

        let data = { email:email, numeroFiche:numeroFiche }

        envoiTicket(data)
        .then(res=>{
            if (res) {
                setEnvoiTickets({
                    ...envoiTickets,
                    ticket:true
                });
            }
        })
        .catch((err) => {
            console.log(err);
            setIsLoading(false);
            setErrorMsg("Une erreur s'est produite, veuillez réessayer");
        });
    }

    const cancelEnvoiTicket = () => {
        setEnvoiTickets({
            ...envoiTickets,
            ticket:true
        });
    }

    const onChangeEmail = (e) => {
        setEmail(e.target.value);
        setErrorMsg(null);
    }

    const onKeyDown = (e) => {
        if (e.keyCode === 27) {
            cancelEnvoiTicket();
        }
        else if (e.keyCode === 13 ){
            confirmEnvoiTicket();
        }
    }

    return (
        <Modal closeCallback={cancelEnvoiTicket} className="modalTicket">
            <span>Envoyer un ticket ?</span>
            <TextInput label='email' name='email' value={email} onChange={onChangeEmail} onKeyDown={onKeyDown} autoFocus={true}/>
            {errorMsg != null ? <span className="error">{errorMsg}</span> : null}
            {isLoading ?
                <Loader width={25} height={25}/> :
                <div className="modalTicketFooter">
                    <StandardButton label="Oui" onClick={confirmEnvoiTicket}/>
                    <StandardButton label="Non" onClick={cancelEnvoiTicket}/>
                </div>
            }
        </Modal>
    )
}

function ModalVoucher ({numeroFiche, envoiTickets, setEnvoiTickets}) {

    const [file, setFile] = useState('loading');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const reservation = useSelector((state) => state.fiche.reservation);

    useEffect(() => {
        let params = {}
        if (reservation != null) params = {interloc : reservation.Num_interloc}
        getVoucher(numeroFiche, params)
        .then(res => {
            if (res.type.includes('application/json')) throw new Error (res);
            setFile(window.URL.createObjectURL(res));
        })
        .catch(error =>{
            setFile(null);
            console.log(error);
            dispatch(addErrorMessage({type:"error", message:"Le voucher n'a pas pu être généré"}));
            navigate('/');
        });
    }, []);

    const onCloseModal = () => {
        setEnvoiTickets({
            ...envoiTickets,
            voucher:true
        });
    }


    return (
        file == null ? 
        null :
        <Modal closeCallback={onCloseModal} className="modalVoucher" width="90vw" height="90vh">
            <div className="frameContainer">
                { file === "loading" ?
                    <Loader/> :
                    <iframe className="pdfFrame" src={file}/>
                }      
            </div>
        </Modal>
    )
}