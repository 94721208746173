

import { useEffect, useState } from 'react';
import { PiggyBankIcon } from '../components/Icons';
import { BlockTitle, Title } from '../components/Title';
import { DateInput, LockedInput, SelectInput, TextAreaInput, TextInput } from '../components/Inputs';
import dayjs from 'dayjs';

import '../styles/caisse.css';
import '../styles/remisesEnBanque.css';
import { StandardButton, UnderlindedButton } from '../components/Buttons';
import { ErrorTooltip } from '../components/Tooltips';
import { addErrorMessage } from '../features/errorSlice';
import { useDispatch } from 'react-redux';
import { enregistreRemiseEnBanque, getTypesRemiseEnBanque, updateTypeRemise } from '../api/remisesApi';
import { getListePaiements } from '../api/paiementsApi';
import { DialogWithLoading, Modal } from '../components/Modals';
import Loader from '../components/Loader';
import { VideoLink } from '../components/VideoLink';

export default function RemisesEnBanque () {
    
    const [currentTypeRemise, setCurrentTypeRemise] = useState(null);
    const [typeRemise, setTypeRemise] = useState([]);
    const [modePaiements, setModePaiements] = useState([]);
    const [refresh, setRefresh] = useState(0);

    useEffect(() => {
        getTypesRemiseEnBanque().then(res => setTypeRemise(res.filter(el => el.CodeComptaMagasin != "" && el.CodeComptaMagasin != null)));
    }, [refresh]);

    useEffect(() => {
        if (typeRemise.length>0) {
            getListePaiements().then(res => setModePaiements(res));
        }
    }, [typeRemise]);

    const refreshData = () => {
        setCurrentTypeRemise(null);
        setRefresh(refresh+1);
    }


    return (
        <div className='RemisesEnBanque'>
                <Title title="Remises en banque"><PiggyBankIcon/></Title>
                <div className="Block filtres">
                    <BlockTitle title="Mes types de remise"/>
                    <table>
                        <tbody>
                        {typeRemise.map(type=>{
                            return <LigneTypeRemise key={type.Code} typeRemise={type} setCurrentTypeRemise={setCurrentTypeRemise} currentTypeRemise={currentTypeRemise}/>
                        })}
                        <tr>
                            <td colSpan={2} style={{textAlign:'end'}}>
                                <VideoLink link="https://www.youtube.com/embed/QEjkjRDZ0zI?si=MUD9-YQPpC_FUY_X" libelle='typesRemiseEnBanque' small={true}/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="Block">
                    {
                        currentTypeRemise != null ?
                        <UpdateTypeRemise setCurrentTypeRemise={setCurrentTypeRemise} typeRemise= {currentTypeRemise} refresh={refreshData}/> :
                        <CreationRemiseEnBanque typeRemise={typeRemise} modePaiements={modePaiements}/> 
                    }
                </div>
                <VideoLink link="https://www.youtube.com/embed/UBKjFgezggw?si=SEIB2NhN0hR0Nqts" libelle='remiseEnBanque'/>
        </div>
    )
}

function LigneTypeRemise ({typeRemise, setCurrentTypeRemise, currentTypeRemise}) {
    return (
        <tr className={"ligneRemise" + (typeRemise.Code === currentTypeRemise?.Code ? " selected" : "")} onClick={() => setCurrentTypeRemise (typeRemise)}>
            <td>{typeRemise.libelle === "" ? typeRemise.Code : typeRemise.libelle}</td>
            <td>{typeRemise.CodeComptaMagasin}</td>
        </tr>
    )
}


function CreationRemiseEnBanque ({typeRemise, modePaiements}) {


    const dispatch = useDispatch();
    const[file, setFile] = useState(null);
    const[messages, setMessages] = useState([]);
    const [formData, setFormData] = useState({
        date: dayjs(),
        montant: 0.00,
        type: "",
        codeComptable: " ",
        commentaire: "",
        error:false
    });

    const resetFormData = () => {
        setFormData({
            date: dayjs(),
            montant: 0.00,
            codeTypeRemise: "",
            codeComptable: " ",
            codePaiement: "",
            commentaire: "",
            error:false
        })
    }

    const onChange = (event) => {
        const {name, value} = event.target;
        setFormData({...formData, [name] : value, error:false})
    }

    const onChangeDate = (date) => {
        setFormData({...formData, date: date});
    }

    const onChangeTypeRemise = (event) => {
        const {value} = event.target;
        let codeComptable = typeRemise.find(el => el.Code === value)?.CodeComptaMagasin;
        setFormData ({... formData, codeTypeRemise: value, codeComptable: codeComptable, error:false})
    }

    const onClickSaveButton = () => {
        let mnt = parseFloat(formData.montant);
        let newMessages = [];
        if (mnt < 0) {
            newMessages.push("Le montant saisi est négatif, voulez-vous continuer ?");
        }
        if (formData.date.format('YYYY-MM-DD') < dayjs().format('YYYY-MM-DD'))  {
            newMessages.push("La date sélectionnée est passée, voulez-vous continuer ?");
        }

        if (newMessages.length > 0) {
            setMessages([...messages, ...newMessages]);
        }
        else {
            creerRemiseEnBanque();
        }

    }

    const onClickConfirmRemise = () => {
        if (messages.length == 1) {
            console.log("creation remise");
            creerRemiseEnBanque();
        }
        else {
            let newMessages = [...messages];
            newMessages.shift();
            setMessages(newMessages);
        }
    }

    const creerRemiseEnBanque = () => {
        setMessages([]);
        let mnt = parseFloat(formData.montant);
        if (isNaN(mnt) || formData.codeTypeRemise === "" || formData.codePaiement === "") {
            setFormData({...formData, error:true})
            return;
        }
        setFile('loading');

        enregistreRemiseEnBanque({
            codeTypeRemise: formData.codeTypeRemise,
            codePaiement: formData.codePaiement,
            montant: mnt,
            commentaire: formData.commentaire,
            date: formData.date
        })
        .then(res=> {
            if (res.type == null || !res.type.includes('pdf')) {
                if (res.success) {
                    dispatch(addErrorMessage({type:"success", message:'La remise en banque a été enregistrée avec succès'}));
                    dispatch(addErrorMessage({type:"warning", message:'le bordereau de remise n\'a pas pu être édité'}));
                    resetFormData();
                }
                setFile(null);
                return;
            }
            
            setFile(window.URL.createObjectURL(res));
            resetFormData();
        })
        .catch(err =>{
            console.log(err);
            dispatch(addErrorMessage({type:"error"}));
        });       
    }

    return (
        <div className='CreationRemiseEnBanque' style={{width: '100%'}}>
            <BlockTitle title="Saisie d'une nouvelle remise en banque"/>
            <div>
                <div className='date'>
                    <DateInput label="Date de remise" name="date" value={formData.date} onChange={onChangeDate} maxDate={dayjs()}/>
                </div>
                <SelectInput label="Type de remise" name="codeTypeRemise" value={formData.codeTypeRemise} className='type' options={typeRemise.map((el)=> {return {value:el.Code, name:el.libelle === "" ? el.Code : el.libelle}})} onChange={onChangeTypeRemise} required={true}/>
                <LockedInput label="Code comptable" name="codeComptable" value={formData.codeComptable} className='code'/>
                <TextInput label="Montant" type="number" name="montant" step={0.01} value={formData.montant} className='montant' onChange={onChange} required={true}/>
                <SelectInput label="Mode de paiement" name="codePaiement" value={formData.codePaiement} className='codePaiement' options={modePaiements.map((el)=> {return {value:el.Code, name:el.Libelle}})} onChange={onChange} required={true}/>
                <TextAreaInput name="commentaire" className='commentaire' value={formData.commentaire} onChange={onChange}/>
                <div className='submit'>
                    <ErrorTooltip title={'Veuillez renseigner les champs manquants'} open={formData.error} placement={'left'}>
                        <div>
                            <StandardButton onClick={onClickSaveButton} label={"Enregistrer"}/>
                        </div>
                    </ErrorTooltip>
                </div>
            </div>
            {messages.length === 0 ? 
                null :
                <DialogWithLoading
                    confirmText="Oui"
                    cancelText="Non"
                    loading={false}
                    setLoading={() => {}}
                    confirmCallback={onClickConfirmRemise}
                    cancelCallback={() => setMessages([])}>
                    <span>{messages[0]}</span>
                </DialogWithLoading>
            }
            {file == null ? 
                null :
                <Modal width="90vw" height="90vh" closeCallback={() => setFile(null)}>
                    <div className="frameContainer">
                        { file === "loading" ?
                            <Loader/> :
                            <iframe className="pdfFrame" src={file}/>
                        }
                        
                    </div>
                </Modal>
            }
        </div>
        
    )
}


function UpdateTypeRemise ({typeRemise, setCurrentTypeRemise, refresh}) {

    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        libelle: "",
        codeComptable: "",
        error:false,
    });

    useEffect(() => {
        setFormData({
            libelle: typeRemise.libelle === "" ? typeRemise.Code : typeRemise.libelle,
            codeComptable: typeRemise.CodeComptaMagasin,
            error:false,
        })

    }, [typeRemise])


    const onChange = (event) => {
        const {name, value} = event.target;
        setFormData({...formData, [name] : value, error:false})
    }

    const enregistreTypeRemise = () => {
        if (formData.libelle === "") {
            setFormData({...formData, error:true});
            return;
        }

        updateTypeRemise({
            codeTypeRemise : typeRemise.Code,
            libelle: formData.libelle
        }).then(res => {
            if (res) {
                dispatch(addErrorMessage({type:"success", message:"Type de remise modifié avec succès"}));
                refresh();
            }
        })
        .catch(err =>{
            console.log(err);
            dispatch(addErrorMessage({type:"error"}));
        });

    }

    return (
        <div className='CreationCodeComptable' style={{width: '100%'}}>
            <BlockTitle title="Modification du type de remise"/>
            <div>
                <TextInput label="Libellé" name="libelle" value={formData.libelle} className='libelle' onChange={onChange} required={true}/>
                <LockedInput label="Code Comptable" name="codeComptable" value={formData.codeComptable} className='code'/>
                <div className='submit'>
                    <UnderlindedButton label="Abandon" onClick={() => setCurrentTypeRemise(null)}/>
                    <ErrorTooltip title={'Veuillez renseigner les champs manquants'} open={formData.error} placement={'left'}>
                        <StandardButton onClick={enregistreTypeRemise} label="Enregistrer"/>
                    </ErrorTooltip>
                </div>
            </div>
        </div>
    )
}


