import '../styles/loader.css';
import { RotatingLines } from 'react-loader-spinner'

export default function Loader({width, height, containerStyle}) {
    return (
        <div className='loaderContainer' style={containerStyle}>
            <RotatingLines
            visible={true}
            height={height}
            width={width}
            strokeColor='currentColor'
            strokeWidth="3"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"/>
        </div>
        // <div class="loader"></div> 
        // <div>Loading...</div>
    // <div className='loaderContainer'>
    //     <div className="loader" style={{scale:scale}}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    // </div>
    )
}