import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { setConnections, setPopupNouveautes } from '../features/authSlice.js';
import { BackIcon, BikeIcon, BoxIcon, CaisseIcon, ClientIcon, ClipboardIcon, EditIcon, ExitIcon, FileIcon, FilterIcon, GaugeIcon, LocIcon, PaymentMethodIcon, PiggyBank, PiggyBankIcon, PlanningIcon, SettingsIcon, SliderIcon, StatsIcon, TagIcon, TeddyBearIcon, TimeBackIcon, TruckIcon, VideoIcon } from "../components/Icons.js";
import { useEffect, useState } from "react";
import { getConnectionsInfo } from "../api/connectionsApi.js";
import { addErrorMessage } from "../features/errorSlice.js";
import { HomeFooter, HomeHeader } from "../components/HomeHeaderFooter.js";


import '../styles/home.css';
import Loader from "../components/Loader.js";
import { Modal } from "../components/Modals.js";
import { disablePopupNouveautes } from "../api/usersApi.js";
import { ToggleSwitch } from "../components/Inputs.js";


export default function Home ({section}){

    
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    
    const connections = useSelector((state) => state.user.connections);
    const popupNouveautes = useSelector((state) => state.user.parametres.popupNouveautes);
    

    useEffect(() => {
        if (connections == null) {
            setIsLoading(true);
            getConnectionsInfo()
            .then(res => {
                if (!res.skilouApp) dispatch(addErrorMessage({type:"error", message:"Le magasin n'est pas connecté au serveur api, veuillez contacter votre maintenance"}));
                if (!res.mySkilou) dispatch(addErrorMessage({type:"error", message:"Le magasin n'est pas connecté au serveur dashboard, veuillez contacter votre maintenance"}));
               
                dispatch(setConnections({mySkilou:res.mySkilou, skilouApp:res.skilouApp}));
                setIsLoading(false);
            })
            .catch(error =>{
                console.log(error);
                setIsLoading(false);
                dispatch(addErrorMessage({type:"error"}));
            });
        }
    }, []);

    const getButtons = (section, connections) => {
        switch(section) {
            case "Caisse":
                return <CaisseHome connections={connections}/>
            case "GestionLocation":
                return <GestionLocationHome connections={connections}/>
            case "Planning":
                return <PlanningHome connections={connections}/>
            default:
                return <MainHome connections={connections}/>
        }
    }


    return (
        <div className='Home'>
            <div>
                <HomeHeader homeRoute='/'/>
                {
                    section != null ? 
                    <div className="homeBackButton">
                        <button onClick={() => navigate('/')}><BackIcon/></button>
                    </div>
                    :null
                }
            </div>
            {getButtons(section, connections)}
            {isLoading ?
                <div className="overlay"><Loader/></div>
                :null
            }
            {popupNouveautes ?<ModalNouveautes/> : null }
            <HomeFooter/>
        </div>
    )
}


function ModalNouveautes ({}) {

    const dispatch = useDispatch();

    const onCloseModalNouveautes = (disable) => {
        if (!disable) {
            dispatch(setPopupNouveautes({popupNouveautes:false}));
            return;
        }
        disablePopupNouveautes()
        .then(res => {
            dispatch(setPopupNouveautes({popupNouveautes:false}));
        })
        .catch(error =>{
            console.log(error);
        });
    }

    return (
        <Modal className="ModalNouveautes" width="70vw" height="90vh" closeCallback={() => onCloseModalNouveautes(false)}>
            <div className="content">
                <h2>{"Nextou version "+process.env.REACT_APP_NEXTOU_VERSION+" nouveautés"}</h2>
                <h3>Location :</h3>
                <ul>
                    <li>
                        <ClientIcon/>
                        <span>Un nouveau planning trié par client est désormais disponible. Retrouvez-le dans le menu "Consulter les plannings de location".</span>
                    </li>
                    <li>
                        <PlanningIcon/>
                        <span>Dans le planning matériel, une nouvelle coche vous permet de considérer le matériel rendu la veille du départ du client.</span>
                    </li>
                    <li>
                        <EditIcon/>
                        <span>Un nouveau bouton "éditer" vous permet de trier et filtrer les articles affichés lors de la création d'une location.</span>
                    </li>
                    <li>
                        <ClipboardIcon/>
                        <span>Vous avez désormais la possibilité de filtrer les articles affichés dans les prévisions de sorties.</span>
                    </li>
                </ul>
                <h3>Vente :</h3>
                <ul>
                    <li>
                        <EditIcon/>
                        <span>Comme pour la location, vous pouvez désormais trier les familles et articles affichés lors de la création d'une vente</span>
                    </li>
                </ul>
                <h3>Divers :</h3>
                <ul>
                    <li>
                        <SettingsIcon/>
                        <span>Un nouveau menu paramètres est disponible en cliquant sur votre nom d'utilisateur. Vous pouvez notamment y changer le thème couleur de votre interface Nextou.</span>
                    </li>
                    <li>
                        <VideoIcon/>
                        <span>Retrouvez en bas à droite de chaque menu une vidéo explicatives des fonctionnalités de la fenetre concernée.</span>
                    </li>
                </ul>

            </div>
            <div className="footer">
                <ToggleSwitch onChange={() => onCloseModalNouveautes(true)} label="Ne plus afficher ce message"/>
            </div>
        </Modal>
    )



}



function MainHome ({connections}) {

    const device = useSelector((state) => state.device);
    const small = device.width<device.smallBreakpoint;

    return (
        <div className="buttonWrapper">
            <Link to={connections?.skilouApp ? '/vente': '#'} className={connections?.skilouApp ? 'vente': ' vente disabled'}>
                <TagIcon/>
                <span>Vendre des produits</span>
            </Link>
            <Link to={connections?.skilouApp ? '/location': '#'} className={connections?.skilouApp ? 'loc': 'loc disabled'}>
                <LocIcon/>
                <span>Louer du matériel</span>
            </Link>
            <Link to={connections?.skilouApp ? '/caisse': '#'} className={connections?.skilouApp ? '': 'disabled'}>
                <CaisseIcon/>
                <span>{small? "Caisse" : "Gérer la caisse"}</span>
            </Link>
            <Link to={connections?.mySkilou ? '/statistiques': '#'} className={connections?.mySkilou ? '': 'disabled'}>
                <StatsIcon/>
                <span>{small? "Statistiques" : "Consulter les statistiques"}</span>
            </Link>
            <Link to={connections?.skilouApp ? '/planning': '#'} className={connections?.skilouApp ? '': 'disabled'}>
                <PlanningIcon/>
                <span>{small? "Plannings" : "Consulter les plannings de location"}</span>
            </Link>
            <Link to={connections?.skilouApp ? '/stocks/etat': '#'} className={connections?.skilouApp ? '': 'disabled'}>
                <BoxIcon/>
                <span>{small? "Etat des stocks" : "Contrôler l'état des stocks"}</span>
            </Link>
            <Link to={connections?.skilouApp ? '/stocks/entree': '#'} className={connections?.skilouApp ? '': 'disabled'}>
                <TruckIcon/>
                <span>{small? "Entrée en stock" : "Saisir une entrée en stock"}</span>
            </Link>
            <Link to={connections?.skilouApp ? '/produits': '#'} className={connections?.skilouApp ? '': 'disabled'}>
                <TeddyBearIcon/>
                <span>{small? "Produits locaux" : "Administrer les produits locaux"}</span>
            </Link>
            <Link to={connections?.skilouApp ? '/gestion': '#'} className={connections?.skilouApp ? '': 'disabled'}>
                <SliderIcon/>
                <span>{small? "Location" : "Gérer les locations"}</span>
            </Link>
        </div>
    )
}

function CaisseHome ({connections}) {

    const device = useSelector((state) => state.device);
    const small = device.width<device.smallBreakpoint;

    return (
        <div className="buttonWrapper caisse">
            <Link to={connections?.skilouApp ? '/caisse/edition': '#'} className={connections?.skilouApp ? 'edition': 'disabled'}>
                <FileIcon/>
                <span>{small? "Edition" : "Editer la caisse"}</span>
            </Link>
            <Link to={connections?.skilouApp ? '/caisse/correction': '#'} className={connections?.skilouApp ? '': 'disabled'}>
                <PaymentMethodIcon/>
                <span>{small? "Correction" : "Corriger la caisse"}</span>
            </Link>
            <Link to={connections?.skilouApp ? '/caisse/remises': '#'} className={connections?.skilouApp ? '': 'disabled'}>
                <PiggyBankIcon/>
                <span>{small? "Remise en banque" : "Effectuer une remise en banque"}</span>
            </Link>
        </div>
    )
}

function PlanningHome ({connections}) {

    const device = useSelector((state) => state.device);
    const small = device.width<device.smallBreakpoint;

    return (
        <div className="buttonWrapper planning">
            <Link to={connections?.skilouApp ? '/planning/materiel': '#'} className={connections?.skilouApp ? 'edition': 'disabled'}>
                <BikeIcon/>
                <span>{small? "Matériel" : "Planning par matériel"}</span>
            </Link>
            <Link to={connections?.skilouApp ? '/planning/client': '#'} className={connections?.skilouApp ? '': 'disabled'}>
                <ClientIcon/>
                <span>{small? "Client" : "Planning par client"}</span>
            </Link>
        </div>
    )
}

function GestionLocationHome ({connections}) {

    const device = useSelector((state) => state.device);
    const small = device.width<device.smallBreakpoint;

    return (
        <div className="buttonWrapper retours">
            <Link to={connections?.skilouApp ? '/previsions': '#'} className={connections?.skilouApp ? '': 'disabled'}>
                <ClipboardIcon/>
                <span>{small? "Prévisions" : "Télécharger les prévisions de sorties/retours"}</span>
            </Link>
            <Link to={connections?.skilouApp ? '/retours/manuels': '#'} className={connections?.skilouApp ? '': 'disabled'}>
                <TimeBackIcon/>
                <span>{small? "Retour anticipé" : "Effectuer un retour anticipé"}</span>
            </Link>
            <Link to={connections?.skilouApp ? '/articles/reduction': '#'} className={connections?.skilouApp ? '': 'disabled'}>
                <ExitIcon/>
                <span>{small? "Sortie du parc" : "Sortir un article du parc"}</span>
            </Link>
            <Link to={connections?.skilouApp ? '/articles/quantites': '#'} className={connections?.skilouApp ? '': 'disabled'}>
                <GaugeIcon/>
                <span>{small? "Quantités" : "Définir les quantités disponibles"}</span>
            </Link>
        </div>
    )
}