import { useState } from "react";
import { TagIcon, ChevronIcon } from "../Icons";
import { LigneProduit } from "./LigneProduit";
import { useDispatch, useSelector } from "react-redux";
import Loader from '../Loader';
import { addPendingProduit } from "../../features/ficheSlice";
import ListProduitButtons from "../ListProduitButtons";
import { BlockTitle } from "../Title";

export function Vente({socket}) {

    const produits = useSelector((state) => state.fiche.produits);
    const [isOpen, setIsOpen] = useState(true);

    const totalVente = produits.reduce((total, product) => (total = total + product.prixTotal),0);

    const dispatch = useDispatch();

    const addProduit = (produit) => {
        dispatch(addPendingProduit());
        socket.current.emit("addProduit", {ean13:produit.ean13});
    }

    return (
        <div className='Block Vente'>
            <BlockTitle title={"Vente"} onClick={() => setIsOpen(!isOpen)} icon={<TagIcon/>}>
                {totalVente > 0 ? <span>{totalVente.toFixed(2)}&euro;</span> : null}
                <button tabIndex={-1} className={isOpen ? 'open':'close'}><ChevronIcon/></button>
            </BlockTitle>
  
            <div style={{display : isOpen ? "block":"none"}}>
                <ListProduitButtons onSelectCouleur={addProduit} alerteStock0={true}/>
                <PanierVente socket={socket}/>
            </div>
        </div>
    )
}

function PanierVente ({socket}) {

    const produits = useSelector((state) => state.fiche.produits);
    const pendingProduits = useSelector((state) => state.fiche.pendingProduits);

    const device = useSelector((state) => state.device);
    const small = device.width<device.smallBreakpoint

    return(
        <table className='Panier Vente'>
        <thead>
            <tr>
                { small ? null : <th>Code</th> }
                <th style={{width: "20%"}}>{small ?  "Lib." : "Libellé"}</th>
                {small ? null : <th>Stock</th>}
                <th>Taille</th>
                <th>{small ? "Coul." : "Couleur"}</th>
                {small ? null : <th>Prix Unit.</th>}
                <th>{small ? "Qté." : "Quantité"}</th>
                <th>{small ? "Rem.": "Remise"}</th>
                <th>Total</th>
                <th style={small ? {width: "10%"} : {width: "5%"}}></th>
            </tr>
        </thead>

        <tbody>
            {pendingProduits>0 ? Array(pendingProduits).fill(true).map((_, i) => <tr key={i}><td colSpan={device.width>device.smallBreakpoint ? 10:7}><Loader height={20} width={20}/></td></tr>):null}

            {produits.slice().reverse().map((produit) => {
                return <LigneProduit key={produit.indice} produit={produit} socket={socket}></LigneProduit>
            })} 
        </tbody>
    </table>
    )
}