import React, { useRef, useEffect } from "react"
import { init, getInstanceByDom } from "echarts"
import { useSelector } from "react-redux";

import '../../styles/charts.css';


export function EChart({ option, style, settings, loading, theme }) {
  const chartRef = useRef(null);

  const device = useSelector((state) => state.device);
  const small = device.width<device.smallBreakpoint;

  useEffect(() => {
    // Initialize chart
    let chart
    if (chartRef.current !== null) {
      chart = init(chartRef.current, theme)
    }

    function resizeChart() {
      console.log("resize");
      console.log(chart);
      chart?.resize();
    }
    window.addEventListener("resize", resizeChart)

    // Return cleanup function
    return () => {
      chart?.dispose()
      window.removeEventListener("resize", resizeChart)
    }
  }, [theme])

  useEffect(() => {
    // Update chart
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current)
      chart.setOption(option, settings)
    }
  }, [option, settings, theme]) // Whenever theme changes we need to add option and setting due to it being deleted in cleanup function

  useEffect(() => {
    // Update chart
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current)
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      loading === true ? chart.showLoading() : chart.hideLoading()
    }
  }, [loading, theme])

  return (
    <div ref={chartRef} style={{ height: "100px", ...style }} />
  )
}

