import { useState } from "react";
import { XIcon } from "../Icons";
import { NumberField, PlusMinusNumberField } from "./EditableField";
import { setPendingFieldProduit } from "../../features/ficheSlice";
import store from "../../app/store";
import Loader from "../Loader";
import { useSelector } from "react-redux";
import {Dialog} from "../Modals";

export function LigneProduit ({produit, socket}) {

   const [status, setStatus] = useState(null); //permet de savoir quel champ est en cours d'edition
   const device = useSelector((state) => state.device);

   const [modalOpen, setModalOpen] = useState(false);

   function deleteVenteProduct() {
      setModalOpen(false);
      setStatus("delete");
      socket.current.emit("removeProduit", {ean13:produit.ean13, indice:produit.indice, quantite:produit.quantite});
      store.dispatch(setPendingFieldProduit({id:produit.indice, fieldName:'delete', pending : true}));
   } 

   function onQuantiteChange (newQuantite) {
      newQuantite= parseInt(newQuantite);
      if (newQuantite === produit.quantite) {
         setStatus(null);
         return;
      }

      if (isNaN(newQuantite) || newQuantite === 0) {
         deleteVenteProduct();
         return;
      }

      store.dispatch(setPendingFieldProduit({id:produit.indice, fieldName:'quantite', pending : true}));
      socket.current.emit("updateQuantite", {ean13:produit.ean13, indice:produit.indice, quantite:newQuantite});
      setStatus(null);

   }

   function onRemiseChange (newRemise) {
      newRemise = parseInt(newRemise);
      if (isNaN(newRemise) || newRemise<0) newRemise = 0;
      
      let newPrix = (100-newRemise)* produit.prixUnitaire * produit.quantite/100;
      onPrixChange(newPrix);
   }

   function onPrixChange (newPrix) {
      newPrix = parseFloat(newPrix);
      let prixBase = produit.prixUnitaire*produit.quantite;

      if (isNaN(newPrix) || newPrix>prixBase || newPrix == produit.prixTotal) {
         setStatus(null);
         return;
      }
      store.dispatch(setPendingFieldProduit({id:produit.indice, fieldName:'prix', pending : true}));
      socket.current.emit("updatePrix", {ean13:produit.ean13, indice:produit.indice, quantite:produit.quantite, prix:newPrix});
      setStatus(null);
      
   }

   function updateStatus (field, value) {
      if (value) setStatus(field);
      else setStatus(null);
   }


   return (
      <tr className="LignePanier">
         {device.width>device.smallBreakpoint ? <td>{produit.code}</td> : null}
         <td>{produit.nom}</td>
         {device.width>device.smallBreakpoint ? <td>{produit.stock}</td> : null}
         <td>{produit.tailleLibelle}</td>
         <td>{produit.couleurLibelle}</td>
         {device.width>device.smallBreakpoint ? <td>{produit.prixUnitaire}&nbsp;&euro;</td> : null}
         <td className='editable'>
            <PlusMinusNumberField 
               step = {1}
               inputStyle = {{width: 20}} 
               edit = {status === "quantite"} 
               onFocusOut = {onQuantiteChange} 
               initialValue = {produit.quantite} 
               loading = {produit.pendingUpdate.quantite}
               setEdit = {(v)=>updateStatus("quantite", v)}/>
         </td>
         <td className='editable'>
            <NumberField 
               initValue={produit.remise}
               onFocusOut={onRemiseChange}
               step={1}
               inputStyle={{width:30}}
               unit={'%'}
               edit={status === "remise"}
               loading = {produit.pendingUpdate.prix}
               setEdit={(v)=>updateStatus("remise", v)}/>
         </td>
         <td className='editable'>
            <NumberField
               initValue={produit.prixTotal}
               onFocusOut={onPrixChange}
               step={0.01}
               inputStyle={{width:50}}
               unit={String.fromCodePoint(8364)}
               edit={status === "prixTotal"}
               loading = {produit.pendingUpdate.prix}
               setEdit={(v)=>updateStatus("prixTotal", v)}/>
         </td>
         <td>
            <button onClick={() => setModalOpen(true)}><XIcon/></button>
            {produit.pendingUpdate.delete ? <div className="overlay"><Loader width={20} height={20}/></div> : null}
            {modalOpen ? 
               <Dialog confirmText="Oui"
                  confirmCallback={deleteVenteProduct}
                  cancelText="Non"
                  cancelCallback={() => setModalOpen(false)}>
                  <span>Supprimer le produit de la fiche ?</span>
               </Dialog>
               :null
            }
         </td>
      </tr>
   )
}