import { Tooltip } from "@mui/material";

export function ErrorTooltip ({children, title, open, placement, arrow=true, openOnClick=false}) {

    let componentsProps={
        tooltip: {
          sx: {
            backgroundColor: 'var(--error-bg-color)',
            color: 'var(--error-color)',
            fontSize:'13px',
            fontWeight: 600
          }
        },
        arrow: {
            sx: {
              color: 'var(--error-bg-color)'
            }
          }
    }

    return (
        <Tooltip 
            title={title} 
            sx={{fontSize:30}}
            componentsProps={componentsProps}
            arrow={arrow} 
            placement={placement}
            disableHoverListener={!openOnClick} 
            disableTouchListener={true} 
            disableFocusListener={true} 
            open={open}
            >
            {children}
        </Tooltip>
    )
}

export function InfoTooltip ({children, title, placement, disabled=false}) {

  let componentsProps={
      tooltip: {
        sx: {
          backgroundColor: 'var(--info-bg-color)',
          color: 'var(--text-color)',
          fontSize:'13px',
          fontWeight: 600
        }
      },
      arrow: {
          sx: {
            color: 'var(--info-bg-color)'
          }
        }
  }

  return (
      <Tooltip 
          title={title} 
          sx={{fontSize:30}}
          componentsProps={componentsProps}
          arrow 
          disableHoverListener={disabled} 
          disableTouchListener={true} 
          disableFocusListener={true} 
          placement={placement}>
          {children}
      </Tooltip>
  )
}