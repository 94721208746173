import { useDispatch, useSelector } from "react-redux";
import { getPacks } from "../api/articlesApi";
import { addErrorMessage } from "../features/errorSlice";
import { useEffect, useState } from "react";
import Loader from "./Loader";


import '../styles/listePacks.css'
import { SelectInput } from "./Inputs";

export default function ListePacks ({onClickPack, selectedPack}) {

    const dispatch = useDispatch();
    const [packs, setPacks] = useState(null);

    const device = useSelector((state) => state.device);
    const small = device.width<device.smallBreakpoint;

    useEffect(() => {
        getPacks()
        .then(res=> {
            console.log(res);
            setPacks(res.packs);
        })
        .catch(err =>{
            console.log(err);
            setPacks([]);
            dispatch(addErrorMessage({type:"error", message:"Impossible de charger la liste des articles"}));
        });

        return () => {
            setPacks(null);
        };
    }, []);

    const onChangePackSelect = (e) => {
        let id = parseInt(e.target.value);
        let pack = packs.find(p => p.id === id);
        onClickPack(pack);
    }

    return (
        <div className="ListePacks">
            <div>
                {packs == null ? 
                    <Loader/> :
                    (small ? 
                        <SelectInput options={packs.map(p => {return {name:p.nom, value:p.id}})} value={selectedPack?.id} onChange={onChangePackSelect}/> :
                        packs.map((p) => {
                            let className = selectedPack?.id === p.id ? 'selected' : ''
                            return <button key={p.id} className={className} onClick={()=>onClickPack(p)}>{p.nom}</button> 
                        })
                    )
                }
            </div>
        </div>
    )
}