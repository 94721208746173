import { useState, useEffect } from "react";
import { TruckIcon, XIcon } from "../components/Icons"
import ListProduitButtons from "../components/ListProduitButtons";
import {PlusMinusNumberField} from "../components/fiche/EditableField";
import {Dialog, Modal} from "../components/Modals";
import { CustomableButton } from "../components/fiche/FicheButtons";
import { useDispatch, useSelector } from "react-redux";
import { Title } from "../components/Title";
import { addErrorMessage } from "../features/errorSlice";
import { mouvementStock } from "../api/stockApi";
import Loader from "../components/Loader";



import '../styles/entreeStock.css'
import { VideoLink } from "../components/VideoLink";


export default function EntreeStock (){

    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        produits:[],
        counter:0
    })
    const [showDialog, setShowDialog] = useState(false);
    const [file, setFile] = useState(null);
    const [showWaningDialog, setShowWarningDialog] = useState(false);
    const [key, setKey] = useState(0);

    const device = useSelector((state) => state.device);
    const small = device.width<device.smallBreakpoint

    const addProduitStock = (produit) => {
        
       setFormData({
            ...formData,
            produits :[
                ...formData.produits,
                {
                    id: formData.counter,
                    ean13: produit.ean13,
                    code:produit.codeProduit,
                    libelle:produit.nomProduit,
                    taille: produit.nomTaille,
                    couleur: produit.libelle,
                    prix: produit.prixTTC,
                    stockActuel: produit.stock,
                    quantiteReceptionnee: 1
                }
            ],
            counter:formData.counter +1
        }
       )
    }

    const removeProduitStock = (id) => {
        setFormData({
            ...formData,
            produits: formData.produits.filter(el=>el.id !== id)
        })
     }

    const resetFormData = () => {
        setFormData({
            fournisseur:null,
            produits:[],
            counter:0
            
        });
        setShowWarningDialog(false);
        setShowDialog(false);
    }
    const enregistreEntreeStock = () => {
        
        if (formData.produits.length == 0) return;
        let produits = formData.produits.map((p)=>{
            return {
                ean13: p.ean13,
                quantite: p.quantiteReceptionnee
            }
        })
        setShowDialog(false);
        setFile('loading');
        mouvementStock('entree', produits)
        .then(res=> {
            if (res.type == null || !res.type.includes('pdf')) {
                if (res.success) {
                    dispatch(addErrorMessage({type:"success", message:'L\'entrée en stock a été enregistrée avec succès'}));
                    dispatch(addErrorMessage({type:"warning", message:'le récapitulatif n\'a pas pu être édité, essayez de le générer de nouveau depuis le menu contrôle de stock'}));
                    resetFormData();
                    setKey(key+1);
                }
                setFile(null);
                return;
            }
            
            setFile(window.URL.createObjectURL(res));
            resetFormData();
            setKey(key+1);
        })
        .catch(error =>{
            console.log(error);
            dispatch(addErrorMessage({type:"error"}));
            setFile(null);
        });
    }

    useEffect(() => {
        window.addEventListener("beforeunload", alertUser);
        return () => {
        window.removeEventListener("beforeunload", alertUser);
        };
    }, []);

    const alertUser = (e) => {
        e.preventDefault();
    };

    return (
        <div className='EntreeStock'>
            <Title title="Entrée en stock"><TruckIcon/></Title>
            <div className="Block">
                <div>
                    <ListProduitButtons key={key} onSelectCouleur={addProduitStock} details={true} resetAffichageOnSelect={false}/>
                    {formData.produits.length>0 ?
                        <div className="ButtonList">
                            <CustomableButton label={'Enregistrer'} className='couleur' onClick={()=> setShowDialog(true)}/>
                        </div>
                        : null
                    }
                    <table className='Panier Vente'>
                        <thead>
                            <tr>
                                {small ? null :<th>Code</th>}
                                <th>Libellé</th>
                                <th>Taille</th>
                                <th>{small ? "Coul." :"Couleur"}</th>
                                <th>Prix Unit.</th>
                                <th>{small ? "Stk. Actuel" :"Stock Actuel"}</th>
                                <th>{small ? "Qté. Reçue" :"Quantité Reçue"}</th>
                                <th style={small ? {width: '10%'} : null}></th>
                            </tr>
                        </thead>

                        <tbody>
                            { formData.produits.slice().reverse().map((produit) => {
                                return <LigneProduitStock key={produit.id} produit={produit} small={small} removeProduit={removeProduitStock}/>
                            })} 
                        </tbody>
                    </table>
                </div>
            </div>

            {showDialog ?
                <Dialog 
                    confirmText="oui"
                    confirmCallback={enregistreEntreeStock}
                    cancelText="non"
                    cancelCallback={()=>setShowDialog(false)}>
                    <span>Enregistrer le stock saisi ?</span>
                </Dialog>
                :null
            }
            {showWaningDialog ?
                <Dialog 
                    confirmText="oui"
                    confirmCallback={resetFormData}
                    cancelText="non"
                    cancelCallback={()=>setShowWarningDialog(false)}>
                    <span>Abandonner la saisie en cours ?</span>
                </Dialog>
                :null
            }
            {file == null ? 
                null :
                <Modal width="90vw" height="90vh" closeCallback={() => setFile(null)}>
                    <div className="frameContainer">
                        { file === "loading" ?
                            <Loader/> :
                            <iframe className="pdfFrame" src={file}/>
                        }
                        
                    </div>
                </Modal>
            }   
            <VideoLink link="https://www.youtube.com/embed/CBrw-cuQOq0?si=0AX5TJSZfrMMAr_m" libelle='entreeStock'/>
        </div>
    )
}


export function LigneProduitStock ({produit, removeProduit, small}) {
    const [status, setStatus] = useState('quantite');

    const onQuantiteChange = (val) => {
        val = parseInt(val);
        if (isNaN(val)) return;
        produit.quantiteReceptionnee = val;
        setStatus(null);
    }

    const updateStatus = (field, value) => {
        if (value) setStatus(field);
        else setStatus(null);
    }
   
    return (
       <tr className="LignePanier">
          {small ? null :<td>{produit.code}</td>}
          <td>{produit.libelle}</td>
          <td>{produit.taille}</td>
          <td>{produit.couleur}</td>
          <td>{produit.prix}&nbsp;&euro;</td>
          <td>{produit.stockActuel}</td>

          <td className='editable'>
            <PlusMinusNumberField 
                initialValue={produit.quantiteReceptionnee} 
                onFocusOut={onQuantiteChange} 
                inputStyle={{width: 20}} 
                step={1}
                edit={status === "quantite"} 
                setEdit={(v)=>updateStatus("quantite", v)}/>
          </td>
          <td><button onClick={()=>removeProduit(produit.id)}><XIcon/></button></td>
       </tr>
    )
 }