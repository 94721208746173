import errorHandler from "../utils/ErrorHandler";

export async function getTypesRemiseEnBanque () {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/remises/types', {
        method: 'GET',
        credentials: 'include',
    });

    if (response.status>=500) throw new Error("getTypesRemiseEnBanque api error");
    let result = await response.json();
    result = errorHandler(result);
    if (!result) return [];
    return result.typesRemise;

}

export async function enregistreRemiseEnBanque(data) {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/remises', {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    })

    if (response.status>=500) throw new Error("enregistreRemiseEnBanque error");
    let result = null;
    if (response.headers.get('content-type').includes('json')) {
        result = await response.json();
        result = errorHandler(result);
    }
    else result = await response.blob();

    return result;
   
}

export async function updateTypeRemise(data) {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/remises/type', {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    })

    if (response.status>=500) throw new Error("updateTypeRemise error");
    let result = await response.json();
    return errorHandler(result);
   
}