import { useEffect, useRef, useState } from "react";
import { DateInput, ToggleSwitch } from "../components/Inputs";
import { StandardButton } from "../components/Buttons";
import { useDispatch, useSelector } from "react-redux";
import { addErrorMessage } from "../features/errorSlice";
import { ClipboardIcon, FilterIcon, PdfIcon } from "../components/Icons";
import dayjs from "dayjs";

import { previsionsRetour } from "../api/retoursApi";
import {BlockTitle, Title} from "../components/Title";
import Filler from "../components/Filler";
import { previsionsSorties } from "../api/sortiesApi";

import '../styles/previsions.css';
import { disabledPrevisionsSortiesPack, getPacks } from "../api/articlesApi";
import Loader from "../components/Loader";
import { VideoLink } from "../components/VideoLink";

export default function PrevisionsSortiesRetours (){

    const dispatch = useDispatch();

    const frameRef = useRef(null);

    const [filter, setFilter] = useState(false);
    const [isLocked, setIsLocked] = useState(null);
    const [loading, setLoading] = useState(false);

    const device = useSelector((state) => state.device);
    const small = device.width<device.smallBreakpoint

    const [params, setParams] = useState({
        dateSortie: dayjs(),
        dateRetour: dayjs(),
    })

    const onClickEditeButtonRetours = () => {
        setLoading(true);
        setIsLocked(true);
        frameRef.current.src = 'about:blank';
        //TODO : check dates values
        //TODO : handle error
        previsionsRetour({dateDebut:params.dateRetour, dateFin:params.dateRetour})
        .then((data) =>{
            console.log(data);
            frameRef.current.src = window.URL.createObjectURL(data);
            setIsLocked(false);
            setLoading(false);
        })
        .catch(error =>{
            console.log(error);
            dispatch(addErrorMessage({type:"error"}));
            setLoading(false);
            setIsLocked(false);
        });
    }

    const onClickEditeButtonSorties = () => {
        setFilter(false);
        setLoading(true);
        frameRef.current.src = 'about:blank';
        //TODO : check dates values
        //TODO : handle error
        previsionsSorties(params.dateSortie.format('YYYY-MM-DD'))
        .then((data) =>{
            console.log(data);
            frameRef.current.src = window.URL.createObjectURL(data);
            setLoading(false);
        })
        .catch(error =>{
            console.log(error);
            dispatch(addErrorMessage({type:"error"}));
            setLoading(false);
        });
    }

    const onChangeDateRetour = (val) =>{
        setParams({ 
            ...params, 
            dateRetour: val
        });
    }

    const onChangeDateSortie = (val) =>{
        setParams({ 
            ...params, 
            dateSortie: val
        });
    }

    return (
        <div className='Previsions'>
            <Title title="Prévisions" backTo="/gestion"><ClipboardIcon/></Title>
            <div className="Block sorties">
                <div className="filtresColumn">
                    <h3>{small ? "Sorties :" : "Prévisions des sorties :"}</h3>
                    <DateInput name="dateSortie" value={params.dateSortie} onChange={onChangeDateSortie} style={{margin:'0 auto'}}/>
                    <StandardButton label="Editer" onClick={onClickEditeButtonSorties} isLocked={isLocked || filter}/>
                </div>
                <div>
                    <button onClick={() => setFilter(true)}>
                        <FilterIcon width={15} height={15}/>
                        <span>{small ? "Filtrer" : "Filter les prévisions de sorties"}</span>
                    </button>
                </div>
            </div>
            <div className="Block retours">
                <div className="filtresColumn">
                    <h3>{small ? "Retours :" : "Prévisions des retours :"}</h3>
                    <DateInput name="dateRetour" value={params.dateRetour} onChange={onChangeDateRetour} style={{margin:'0 auto'}}/>
                    <StandardButton label="Editer" onClick={onClickEditeButtonRetours} isLocked={isLocked || filter}/>
                </div>
            </div>
            <div className="Block">
                {filter ? 
                    <FilterPacks setFilter={setFilter}/> : 
                    <div className="frame">
                        <Filler 
                            loading={loading}
                            text="Sélectionnez une date pour afficher les prévisions de sorties ou de retour">
                            <PdfIcon/> 
                        </Filler>
                        <iframe ref={frameRef}/>
                    </div>
                }
            </div>
            {/* <VideoLink link={"https://www.youtube.com/embed/CXbq3OSRzWc?si=9Mn89hF9Ruiefc4G"} libelle='previsionsSortiesRetours'/> */}
        </div>
    )
}

function FilterPacks ({setFilter}) {

    const [packs, setPacks] = useState(null);
    const [all, setAll] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        getPacks()
        .then(res=> {
            setPacks(res.packs);
        })
        .catch(err =>{
            console.log(err);
            setPacks([]);
            dispatch(addErrorMessage({type:"error", message:"Impossible de charger la liste des articles"}));
        });
    }, []);

    const onChangePackDisabled = (id_pack, checked) => {
        let index = packs.map(function(e) { return e.id; }).indexOf(id_pack);
        let newPacks = [...packs];
        newPacks[index].disabled = !checked;
        setPacks(newPacks);
    }

    const onClickSaveFilter = () => {
        let listePacks = packs.map(el => {return {id:el.id, disabled:el.disabled}});

        disabledPrevisionsSortiesPack({packs:listePacks})
        .then(res=> {
            setFilter(false);
            dispatch(addErrorMessage({type:"success", message:"Les modifications ont été enregistrées avec succès"}));
        })
        .catch(err =>{
            console.log(err);
            dispatch(addErrorMessage({type:"error"}));
        });
    }

    const onChangeAll = (e) => {
        let tmpPacks = [...packs];
        tmpPacks = tmpPacks.map (p => {return {...p, disabled: !e.target.checked}});
        setPacks(tmpPacks);
        setAll(e.target.checked);
    }



    return (
        <div className="FilterPacks">
            <BlockTitle title="Filter les prévisions de sorties"/>
            <div className="buttons">
                <StandardButton label="Enregistrer" onClick={onClickSaveFilter}/>
                <StandardButton label="Abandon" onClick={() => setFilter(false)}/>
            </div>
            <div style={{height:'100%', overflowY:'scroll'}}>
                <div style={{}}>
                    {packs == null ? 
                        <Loader/> :
                        <table>
                             <tr>
                                <td>
                                    <ToggleSwitch
                                        label="" 
                                        name="disabled" 
                                        isChecked={all}
                                        onChange={onChangeAll}
                                    />
                                </td>
                                <td>Tous</td>
                            </tr>
                            <tbody>
                                {packs.map((pack)=>{
                                    return <LignePack key={pack.id} pack={pack} onChangePackDisabled={onChangePackDisabled}/>
                                })}
                            </tbody> 
                        </table>
                    }
                </div>
            </div>
        </div>
    )
}


function LignePack ({pack, onChangePackDisabled}) {

    const onChangeSwitch = (e) => {
        onChangePackDisabled(pack.id, e.target.checked);
    }
    return (
        <tr>
            <td>
                <ToggleSwitch
                    label="" 
                    name="disabled" 
                    isChecked={!pack.disabled}
                    onChange={onChangeSwitch}
                />
            </td>
            <td>{pack.nom}</td>
        </tr>
    )
}
