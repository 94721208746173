import { createSlice } from '@reduxjs/toolkit'

export const deviceSlice = createSlice({
  name: 'device',
  initialState: {
    width:0,
    smallBreakpoint: 640
  },
  reducers: {
    updateWidth: (state, action) => {
        return {
            ...state,
            width: action.payload.width
        };
       
    }
  }
})

export const { updateWidth } = deviceSlice.actions;
export default deviceSlice.reducer;
