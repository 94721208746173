import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkCodeProduit, createProduits, getParametresProduits } from "../../api/produitsApi";
import { addErrorMessage } from "../../features/errorSlice";
import { SelectInput, SimpleTextInput, TextInput, ToggleSwitch } from "../Inputs";
import { ErrorTooltip, InfoTooltip } from "../Tooltips";
import { ErrorIcon, FilledCheckIcon, InfoIcon } from "../Icons";
import { StandardButton, UnderlindedButton } from "../Buttons";
import {Dialog} from "../Modals";
import Loader from "../Loader";
import { BlockTitle } from "../Title";

export default function CreationProduit ({setCurrentProduit}) {

    const [step, setStep] = useState(0);

    const [formData, setFormData] = useState({
        code:"",
        famille:"",
        fournisseur:"",
        libelle:"",
        taille:"", 
        couleurs:[],
        prixAchat:"",
        prixVente:""
    });

    return (
        [
            <BlockTitle key='title' title="Créer un nouveau produit local"/>,
            (step == 0 ?  
                <FormProduit key='formProduit' formData={formData} setFormData={setFormData} setStep={setStep} setCurrentProduit={setCurrentProduit}/> : 
                <FormStock key='formStock' formData={formData} setStep={setStep} setCurrentProduit={setCurrentProduit}/>
            ) 
        ]
    )
}

function FormProduit ({formData, setFormData, setStep, setCurrentProduit}) {

    const role = useSelector((state) => state.user.role);
    const device = useSelector((state) => state.device);
    const small = device.width<device.smallBreakpoint

    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(true);
    const [codeStatus, setCodeStatus] = useState(null);
    const [parametres, setParametres] = useState({
        Familles:[],
        Fournisseurs:[],
        Couleurs:[],
        Tailles:[],
    });

    const  [errors, setErrors] = useState({
        famille: false,
        fournisseur: false,
        libelle: false,
        libelleLength: false,
        taille: false,
        prixAchat: false,
        prixVente: false,
        couleurs:false,
    });
    
    useEffect(() => {
        getParametresProduits()
        .then((res)=>{
            setParametres({
                Familles: role ==='supervisor' ? res.FamillesSiege : res.FamillesLocale,
                Fournisseurs: res.Fournisseurs,
                Couleurs: res.Couleurs,
                Tailles: res.Tailles 
            });
            setIsLoading(false);
        })
        .catch(error =>{
            console.log(error);
            setIsLoading(false);
            dispatch(addErrorMessage({type:"error"}));
        });
    }, []);

    const handleChange = (e) =>{
        const { name, value } = e.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
        if (name === "libelle") setErrors({...errors, libelle: false, libelleLength:false});
        else setErrors({...errors, [name]: false});
    }

    const handleCodeChange = (newCode) =>{
        setFormData((prevFormData) => ({ ...prevFormData, code: newCode }));
    }

    const nextStep = () => {
        let newError = {...errors};

        if (formData.famille === "") newError.famille =true;
        if (formData.fournisseur === "") newError.fournisseur = true;
        if (formData.libelle === "") newError.libelle = true;
        if (formData.libelle.length > 13) newError.libelleLength = true;
        if (formData.code === "") setCodeStatus('error');
        if (formData.taille === "") newError.taille = true;
        if (isNaN(parseFloat(formData.prixAchat))) newError.prixAchat = true;
        if (isNaN(parseFloat(formData.prixVente))) newError.prixVente = true;
        if (formData.couleurs.length == 0) newError.couleurs = true;
        

        setErrors(newError);

        if (codeStatus !== 'valid') return;


        for (const [key, value] of Object.entries(newError)) {
            if (value) return;            
        }

        let grilleTailles = parametres.Tailles.find((el) => el.Code === formData.taille);
        setFormData({
            ...formData,
            plotsTaille: grilleTailles.Plots
        })
        setStep(1);
        
    }

    const getLibelleGrilleTailles = (grille) => {
        let libelle = grille.Code+ " (";
        grille.Plots.forEach(p => {
            libelle += p.Valeur+", ";
        })

        libelle = libelle.substring(0,libelle.length-2)+")";

        return libelle;

    }

    const handleCouleurChange = (e) =>{
        const { name, checked } = e.target;
        setErrors({...errors, couleurs:false});
        if (checked){
            let couleur = parametres.Couleurs.find(el => el.Code === name);
            setFormData({
                ...formData,
                couleurs: [...formData.couleurs, couleur]
            })
        }
        else {
            setFormData({
                ...formData,
                couleurs: formData.couleurs.filter((el) => el.Code !== name)
            })
        }
    }

    return (
        [<div key="form">
            <div className="formColumn">
                <div style={{display:'flex', justifyContent:'space-between'}}>
                    <span>Informations générales :</span>
                    <InfoTooltip
                        placement="right"
                        title="Si vous ne trouvez pas la famille, la grille de taille ou le fournisseur souhaité dans la liste, veuillez contacter le support Nextou pour le créer">
                        <InfoIcon width={20} height={20}/>
                    </InfoTooltip>
                </div>
                <SelectInput 
                    className={"famille "+ (errors.famille ? "error": null)}
                    label="Famille" 
                    name="famille" 
                    value={formData.famille}
                    onChange={handleChange}
                    options={parametres.Familles.map((el)=> { return {value:el.Code, name:el.Libelle}})} 
                    required={true}/>
                <SelectInput 
                    className={"fournisseur "+ (errors.fournisseur ? "error": null)}
                    label="Fournisseur" 
                    name="fournisseur" 
                    onChange={handleChange}
                    value={formData.fournisseur}
                    options={parametres.Fournisseurs.map((el)=> { return {value:el.Code, name:el.Raison_sociale}})} 
                    required={true}/>
                    <ErrorTooltip open={errors.libelleLength} title="Le libellé ne peut pas dépasser 13 caractères" placement={'right'}>
                        <div>
                            <TextInput 
                                className={"libelle "+ (errors.libelle ? "error": null)}
                                label="Libellé" 
                                name="libelle"
                                value={formData.libelle}
                                onChange={handleChange}/>
                        </div>
                    </ErrorTooltip>

                <CodeProduitField codeStatus={codeStatus} setCodeStatus={setCodeStatus} handleCodeChange={handleCodeChange}/>
            
                <SelectInput
                    className={"taille "+ (errors.taille ? "error": null)} 
                    label="Taille" 
                    name="taille" 
                    value={formData.taille}
                    options={parametres.Tailles.map((el)=> {return {value:el.Code, name:getLibelleGrilleTailles(el)}})} 
                    onChange={handleChange}
                    required={true}/>
                <TextInput 
                    className={"prixAchat "+ (errors.prixAchat ? "error": null)} 
                    label="prix d'achat" 
                    name="prixAchat"
                    value={formData.prixAchat}
                    onChange={handleChange}/>
                <TextInput 
                    className={"prixVente "+ (errors.prixVente ? "error": null)} 
                    label="prix de vente" 
                    name="prixVente"
                    value={formData.prixVente}
                    onChange={handleChange}/>
            </div>
            <div className="formColumn">
                <ErrorTooltip title="Vous devez choisir une couleur au minimum" open={errors.couleurs} placement={small ? "top" : "right"}>
                    <span className="select">Choix des couleurs (1 au minimum) :</span>
                </ErrorTooltip>
                <div className="colorList">
                    {parametres.Couleurs.map((el) => {
                        let isChecked = formData.couleurs.find(c => c.Code === el.Code) != null ;
                        return <ToggleSwitch key={el.Code} label={el.Libelle} name={el.Code} onChange={handleCouleurChange} isChecked={isChecked}/>
                    })}
                </div>
            </div>
        </div>,
        <div key="footer" className="footer">
            { isLoading ? <div className="overlay"><Loader/></div> : null}
            <UnderlindedButton label="Abandon" onClick={() => setCurrentProduit(null)}/>
            <StandardButton isLocked={codeStatus === 'loading'} label="Suivant" onClick={nextStep}/>
        </div>
        ]
    )
}



function CodeProduitField ({codeStatus, setCodeStatus, handleCodeChange}) {
    const [codeProduit, setCodeProduit] = useState("");
    const [errorMessage, setErrorMessage] = useState('');

    const role = useSelector((state) => state.user.role);
    const device = useSelector((state) => state.device);
    const small = device.width<device.smallBreakpoint


    const dispatch = useDispatch();

    const checkCodeValid = (e) => { //TODO : produits siege vs produits locaux
        if (e.target.value == "" || e.target.value.length>8) {
            setCodeStatus("error");
            return;
        }
        setCodeStatus("loading");

        let data = {
            codeProduit:e.target.value,
            produitLocal:role === 'user'
        }

        checkCodeProduit(data)
        .then(res=>{
            console.log(res);
            if (!res.isValid) {
                setCodeStatus("error");
                setErrorMessage(res.message);
            }
            else {
                setCodeStatus("valid");
                handleCodeChange(codeProduit);
                setErrorMessage("");
            }
        })
        .catch((error) => {
            console.log(error);
            dispatch(addErrorMessage({type:"error", message:"Erreur lors de la vérification du code produit"}));
            return false;
        });
    }

    const handleChange = (e) =>{
        setCodeStatus(null);
        setErrorMessage("");
        setCodeProduit(e.target.value);
    }

    return (
        <div className="code">
            <TextInput 
                className={(codeStatus === "error" ? " error": "") + (codeStatus === "valid" ? " valid": "")}
                label="Code Produit" 
                name="code"
                onBlur={checkCodeValid}
                value={codeProduit}
                onChange={handleChange}/>
            <div className="tooltip">
                <InfoTooltip
                    placement="right"
                    title="Le code produit doit être unique et comporter un maximum de 15 caractères (lettres majuscules et chiffres)">
                    <InfoIcon width={20} height={20}/>
                </InfoTooltip>
            </div>
            <div className="checkCode">
                {codeStatus === "loading" ? 
                    <Loader width={20} height={20}/>
                    : null
                } 
                {errorMessage !== "" ? 
                    <ErrorTooltip title={errorMessage} open={true} placement={small ? "bottom":"right"}>
                        <ErrorIcon width={20} height={20} className="error"/>
                    </ErrorTooltip>
                    : null
                }
                {codeStatus === "valid" ? <FilledCheckIcon width={20} height={20} className="check"/> : null}
            </div>
        </div>
    )
}


function FormStock ({formData, setStep, setCurrentProduit}) {

    const role = useSelector((state) => state.user.role);

    const dispatch = useDispatch();
    const [showDialog, setShowDialog] = useState(false);    
    let [data, setData] = useState(formatFormData(formData));
    let [isLoading, setIsLoading] = useState(false);

    function formatFormData (formData) {
        return {
            famille: formData.famille,
            fournisseur: formData.fournisseur,
            code: formData.code,
            libelle: formData.libelle,
            prixAchat: parseFloat(formData.prixAchat),
            prixVente: parseFloat(formData.prixVente),
            grilleTailles: formData.taille,
            tailles: formData.plotsTaille.map((t) => {
                let taille = {
                    code : t.Code,
                    libelle : t.Valeur
                }
                taille.couleurs = formData.couleurs.map((c) => {
                    return {
                        code: c.Code,
                        libelle: c.Libelle,
                        stockIni: 0,
                        stockMini: 0
                    }
                });
                return taille
            })
        }
    }

    function setStock (codeTaille, codeCouleur, fieldName, value)  {
        value = parseInt(value);
        if(isNaN(value)) value=0;

        let tailleIndex = data.tailles.map(function(e) { return e.code; }).indexOf(codeTaille);
        let couleurIndex = data.tailles[tailleIndex].couleurs.map(function(e) { return e.code; }).indexOf(codeCouleur);

        const newTailles = [...data.tailles];
        newTailles[tailleIndex].couleurs[couleurIndex][fieldName] = (value === "" ? "":parseInt(value));

        setData({
            ...data,
            tailles : newTailles
        });
    }

    const [allStock, setAllStock] = useState({
        stockIni:0,
        stockMini:0
    })

    const onChangeAllStock = (e) =>{
        const { name, value } = e.target;
        setAllStock({...allStock, [name]: value});
    }

    const applyStockToAll = (e) => {
        let {name} = e.target;
        let value = parseInt(allStock[name]);
        if(isNaN(value)) value=0;

        const newTailles = [...data.tailles];

        newTailles.forEach((t) => {
            t.couleurs.forEach((c) => {
                console.log(c[name]);
                c[name] = value;
            });
        });

        setData({
            ...data,
            tailles : newTailles
        });

    }

    const onKeyUp = (e) => {
        if (e.keyCode === 13) {
            e.target.blur();
        }
    }



    const saveData = () => {

        let produitsLocaux = role === "user";
        setIsLoading(true);
        createProduits(produitsLocaux, data)
        .then(res=>{
            console.log(res);
            if (res){
                dispatch(addErrorMessage({type:"success", message:"Le produit a été crée avec succès"}));
                setCurrentProduit(null, false);
                //TODO : refresh la liste des produits locaux
            }
            setIsLoading(false);
        })
        .catch((err) => {
            console.log(err);
            dispatch(addErrorMessage({type:"error", message:"Une erreur s'est produite lors de la création du produit, veuillez réessayer"}));
            setIsLoading(false);
        });
    }

    return (
        [<div key="form">
            <table className="table">
                <thead>
                    <tr>
                        <th>Taille</th>
                        <th>Couleur</th>
                        <th>Stock mini</th>
                        {role === "user" ? <th>Stock à la création</th> : null}
                    </tr>
                    <tr>
                        <th colSpan={2}>Appliquer pour tous : </th>
                        <th>
                            <SimpleTextInput
                                style={{width : '50px'}}
                                name='stockMini'
                                onBlur={applyStockToAll}
                                onChange={onChangeAllStock}
                                onKeyUp={onKeyUp}
                                value={allStock.stockMini}/>
                        </th>
                        {role === "user" ? 
                            <th>
                                <SimpleTextInput
                                    style={{width : '50px'}}
                                    name='stockIni'
                                    onBlur={applyStockToAll}
                                    onChange={onChangeAllStock}
                                    onKeyUp={onKeyUp}
                                    value={allStock.stockIni}/>

                            </th>
                            : null
                        }
                    </tr>
                </thead>
                {
                    data.tailles.map ((el) => {
                        return <BlocTaille key={el.code} taille={el} setStock={setStock}/>
                    })
                }
            </table>
        </div>,
        <div key="footer" className="footer">
            {isLoading ? 
                <Loader width={35} height={35}/> :
                [
                    <UnderlindedButton key='cancelBtn' label="Retour" onClick={() => setShowDialog(true)}/>,
                    <StandardButton key='confirmBtn' label="Créer le produit" onClick={saveData}/>
                ]
            }
            {showDialog ?
                <Dialog 
                    confirmText="Oui"
                    confirmCallback={() => setStep(0)}
                    cancelText="Non"
                    cancelCallback={() => setShowDialog(false)}>
                    <span>{"Abandonner les stocks saisis ?"}</span>        
                </Dialog>
                : null
            }  
        </div>
        ]
    )
}

function BlocTaille ({taille, setStock}){
    return (
        <tbody>
            {
                taille.couleurs.map((el, index)=>{
                    return <LigneCouleur couleur={el} codeTaille={taille.code} libelleTaille={taille.libelle} key={el.code} firstRow={index==0} rowSpan={taille.couleurs.length} setStock={setStock}/>
                })
            }
        </tbody>
    )
}

function LigneCouleur ({codeTaille, libelleTaille, couleur, firstRow=false, rowSpan, setStock}) {

    const role = useSelector((state) => state.user.role);

    const onChange = (e) =>{
        const { name, value } = e.target;
        setStock(codeTaille, couleur.code, name, value);
    }

    return (
        <tr>
            { firstRow ? <td rowSpan={rowSpan}>{libelleTaille}</td> : null}
            <td>{couleur.libelle}</td>
            <td>
                <SimpleTextInput
                    style={{width : '50px'}}
                    name='stockMini'
                    onChange={onChange}
                    value={couleur.stockMini}/>
            </td>
            {role === "user" ? 
                <td>
                    <SimpleTextInput
                        style={{width : '50px'}}
                        name='stockIni'
                        onChange={onChange}
                        value={couleur.stockIni}/>
                </td> 
                : null
            }
        </tr>
    )
}