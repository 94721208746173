import { useEffect, useState } from "react"
import { getLogsConsultation } from "../../api/aideApi"
import { useDispatch } from "react-redux";
import { addErrorMessage } from "../../features/errorSlice";
import Loader from "../Loader";
import { ClientIcon, HelpIcon, VideoIcon } from "../Icons";
import { LargeBlock } from "../statistiques/DataBlocks";
import { BarChart } from "../charts/BarChart";

import '../../styles/admin/aideConsultation.css';

export default function AideConsultationWidget (){

    const [by, setBy] = useState('libelle');
    const [data, setData] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        getLogsConsultation(by)
        .then(res=>{
            setData(formatData(res.data, by));
        })
        .catch(error =>{
            console.log(error);
            dispatch(addErrorMessage({type:"error"}));
        });

    }, [by])

    const formatData = (data, by) => {
        if (by === "user")
            return data.map(el => {
                return {
                    name: el.username,
                    valueN: el.nombreN,
                    valueN_1: el.nombreN_1
                }
            });
        else 
            return data.map(el => {
                return {
                    name: el.type + ' ' + el.libelle,
                    valueN: el.nombreN,
                    valueN_1: el.nombreN_1
                }
            });
    }

    const onChangeType = (type) => {
        setData(null);
        setBy(type);
    }

    return (
        <div className="Block AideConsultation">
            <h3>
                <HelpIcon/>
                <span>Consultation de l'aide en ligne</span>
            </h3>
            <LargeBlock 
            valueN={data == null ?  0 : data.reduce((total, el) => (total = total + el.valueN),0)}
            valueN_1={data == null ?  0 : data.reduce((total, el) => (total = total + el.valueN_1),0)}
            title = "Total :"/>
            <div className="buttonsContainer">
                <button onClick={() => onChangeType("user")} className={by === "user" ? "selected" : ""}><ClientIcon/></button>
                <button onClick={() => onChangeType("libelle")} className={by === "libelle" ? "selected" : ""}><VideoIcon/></button>
            </div>
            <div className="chartContainer">
                { data == null ? 
                    <Loader/> : 
                    <BarChart 
                    title = {"Palmarès par " + (by === 'user' ? "utilisateur": "type")}
                    data={data.sort((a,b) => a.valueN > b.valueN_1 ? 0 : 1)} 
                    dataName={"nombre"} 
                    mainColor={'purple'} 
                    style={{height : 50*data.length + 'px'}}/>
                }
            </div>
        </div>
    )
}
