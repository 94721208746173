import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { addErrorMessage } from "../features/errorSlice";
import { getTokenPlanning } from "../api/connectionsApi";
import Loader from "../components/Loader";
import { PlanningIcon } from "../components/Icons";
import {Title} from "../components/Title";

import '../styles/planning.css';
import { VideoLink } from "../components/VideoLink";

export default function Planning (){

    const dispatch = useDispatch();
    const frameRef = useRef(null);
    const { id } = useParams();
    const {type} = useParams();

    const [loading, setLoading] = useState(true);
    const device = useSelector((state) => state.device);

    const role = useSelector((state) => state.user.role);


    useEffect(() => {
        getTokenPlanning(id)
        .then(res=> {
            let url;
            if (type == "client") url = res.baseUrl+'/administration/magasin/planning/planningClient.php?token='+res.token;
            else url = res.baseUrl+'/administration/magasin/planning/planningMateriel.php?token='+res.token+'&al0=0';
            if (device.width < device.small) url += '&affichage=7';
            frameRef.current.src = url;
            setLoading(false);
        })
        .catch(error =>{
            console.log(error);
            dispatch(addErrorMessage({type:"error"}));
        });
    }, []);
    
    return (
        <div className='Planning'>
            <Title title="Planning de location" backTo={role === 'user' ? '/planning':'/supervisor/shops'}><PlanningIcon/></Title>
            <div className="Block">
                {loading ? 
                <div className="filler">
                    <Loader></Loader> 
                </div>
                : null}
                <iframe className="planningFrame" ref={frameRef}></iframe>
            </div>
            <VideoLink link={"https://www.youtube.com/embed/WGxvdX2aI9k?si=c9EXJ3flpOp7sFM2"} libelle='planning'/>

        </div>
    )
}
